export default function ProfileFields() {
  const fields = [
    {
      label: "First Name",
      name: "firstName",
      type: "text",
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "text",
      required: false,
    },
    {
      label: "Phone Number",
      name: "mobileNumber",
      type: "tel",
      required: true,
    },
  ];

  return fields;
}
