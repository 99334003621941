import toast from "react-hot-toast";
import ApiService from "../../apis/ApiService";
import {
  FILE_TYPE_ERROR,
  FILE_TYPE_XLXS,
  IMPORT_DISABLE,
} from "../../components/config/toastMessage";
import { Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { JWT_TOKEN } from "../../components/config/sessionStorage";
import { handleMenuUpdate } from "../menuHandler";
import { showToastWithMessage } from "../../components/config/fieldConfig";
import { formatUploadToastRes } from "../masterFormatUploadToastRes";

// Helper function to upload a file
export const masterUploadFile = async (
  event,
  fileInputRef,
  screenName,
  fetchData,
  setIsUploading,
  handleCurrentError,
  reject,
  resolve
) => {
  try {
    // Fetch JWT token for authorization
    const token = JWT_TOKEN();

    // If token is not available, throw an error
    if (!token) throw new Error("Token not found or invalid");

    sessionStorage.setItem("isFileUpload", true);
    setIsUploading(true);
    const file = event.target.files[0];

    if (!file) return;

    const formData = new FormData();

    let fileType;

    switch (file.type) {
      case FILE_TYPE_XLXS:
        fileType = "xlsx";
        break;
      case "text/csv":
        fileType = "csv";
        break;
      default:
        setIsUploading(false);
        showToastWithMessage(FILE_TYPE_ERROR, true);
        setTimeout(() => {
          if (sessionStorage.getItem("isHovering") === "Not") {
            sessionStorage.setItem("isFileUpload", false);
          }
          reject(FILE_TYPE_ERROR); // Reject with error message
        }, 5000); // Simulate 5 second delay
        return;
    }
    fileInputRef.current.value = ""; // Reset file input regardless of success or failure
    formData.append("file", file);
    formData.append("fileType", fileType);

    ApiService.uploadMaster(formData, token, fileType, screenName)
      .then(async (res) => {
        console.log("File Upload Response:", res);
        if (res?.status === 200 && res?.data) {
          // Call the updateMenu function
          await handleMenuUpdate(screenName);

          // Assuming res.data is like "File uploaded successfully. Total count: 1, Success count: 0, Error count: 1"
          const data = res.data;

          // Extract error count from data using a regular expression
          const match = data.match(/Error count: (\d+)/);
          let errorCount = 0;
          if (match) {
            errorCount = parseInt(match[1], 10);
          }
          setIsUploading(false);
          if (errorCount > 0) {
            let dismissTimeout;
            // Log countdown in console
            let countdown = 5000 / 1000; // Convert milliseconds to seconds
            const interval = setInterval(() => {
              if (countdown > 0) {
                countdown--;
              } else {
                clearInterval(interval);
              }
            }, 1000); // Log every 1 second
            sessionStorage.setItem("isHovering", "Not");
            // Display success toast with a button to view details
            toast(
              (t) => (
                <div
                  onMouseEnter={() => {
                    sessionStorage.setItem("isHovering", true);

                    // Clear the automatic dismissal timeout while hovering
                    clearTimeout(dismissTimeout);
                  }}
                  onMouseLeave={() => {
                    toast.dismiss(); // Dismiss the toast when mouse leaves
                    sessionStorage.setItem("isHovering", false);
                    setTimeout(() => {
                      sessionStorage.setItem("isFileUpload", false);
                    }, 200);
                    // Start the timer to dismiss after the hover
                  }}
                >
                  {formatUploadToastRes(data)}
                  <Button
                    size="small"
                    onClick={() => {
                      handleCurrentError();
                      toast.dismiss(t.id); // Close the toast when the button is clicked
                      setIsUploading(false);
                    }}
                  >
                    View Errors
                  </Button>
                  <div style={{ fontSize: "12px", color: "#6c757d" }}>
                    {IMPORT_DISABLE}
                  </div>
                </div>
              ),
              {
                icon: <ErrorIcon sx={{ color: "#017BFF" }} />,
              }
            );
            setTimeout(() => {
              if (sessionStorage.getItem("isHovering") === "Not") {
                toast.dismiss();
                sessionStorage.setItem("isFileUpload", false);
              }
            }, 5000);
            fetchData(); // Assuming fetchData is a function to fetch updated data
          } else if (errorCount === 0) {
            setIsUploading(false);
            // Display success toast with data
            showToastWithMessage(formatUploadToastRes(data));
            fetchData(); // Fetch updated data
          }
        } else {
          setIsUploading(false);
          // Error response from the server
          showToastWithMessage(res?.response?.data, true);
        }
      })
      .catch((error) => {
        setIsUploading(false);
        // Network error or other unexpected errors during upload
        showToastWithMessage(error, true);
        console.error("errorMessage", error);
      })
      .finally(() => {
        setIsUploading(false);
        // Reset file input regardless of success or failure
        fileInputRef.current.value = "";
      });
    // Simulating API call with setTimeout
    setTimeout(() => {
      if (sessionStorage.getItem("isHovering") === "Not") {
        toast.dismiss();
        sessionStorage.setItem("isFileUpload", false);
      }
      // Resolve for success
      resolve("File uploaded successfully.");
    }, 5000); // Simulate 5 second delay
  } catch (error) {
    setIsUploading(false);
    fileInputRef.current.value = "";
    // File-related error
    showToastWithMessage(error?.message, true);
    console.error("File Error:", error);
  }
};
