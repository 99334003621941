import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { JWT_TOKEN, ROLE_ACCESS } from "../../config/sessionStorage";
import { SPACE_ERROR } from "../../config/toastMessage";
import { ROLE_SCREEN } from "../../config/constants";
import {
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
} from "../../config/fieldConfig";
import { handleApiCall } from "../../../utils/handleApiCall";

export const RoleService = () => {
  const [token, setToken] = useState("");
  const [roleConfigNames, setRoleConfigNames] = useState([]);
  const [loading, setLoading] = useState(true); // Initial loading state is true
  const [treeData, setTreeData] = useState([]);
  const [editLabel, setEditLabel] = useState("");
  const [editLabelObj, setEditLabelObj] = useState({});
  const [roleNameError, setRoleNameError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [addingId, setAddingId] = useState(null);
  const [newRoleName, setNewRoleName] = useState("");
  const [expandedItems, setExpandedItems] = useState([]); // Track expanded items by id
  const newRoleInputRef = useRef(null); // Create a ref for the TextField
  const [isExpanded, setIsExpanded] = useState(true);

  const roleAccess = ROLE_ACCESS();

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const tableData = await ApiService.getRoles(TOKEN);
      // Ensure treeData is an array
      if (Array.isArray(tableData)) {
        setTreeData(tableData);
      } else {
        throw new Error("Expected tableData to be an array");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  // To display Form reportingTo dropdown tableData
  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]); // This will run whenever data changes

  const apiCall = async () => {
    try {
      // Ensure treeData is an array
      if (Array.isArray(treeData)) {
        const extractedData = treeData.map((item) => ({
          roleName: item.roleName,
          id: item.id,
          level: item.rank,
        }));

        // Extract role names into a new array
        const roleNames = Array.isArray(extractedData)
          ? extractedData.map((item) => item.roleName)
          : [];

        setRoleConfigNames(roleNames);
      } else {
        throw new Error("Expected treeData to be an array");
      }
    } catch (error) {
      console.error("Error in apiCall:", error);
    }
  };

  const handleEdit = (item) => {
    try {
      // Ensure item is a valid object and not an array
      if (typeof item !== "object" || item === null || Array.isArray(item)) {
        throw new Error("Item must be an object");
      }

      // Ensure item has the required properties
      if (!item.id || !item.roleName) {
        throw new Error("Item must have 'id' and 'roleName'");
      }
      setEditLabelObj(item);
      setEditingId(item.id);
      setNewRoleName(item.roleName); // Set input to current role name for editing
      setAddingId(null); // Close the add text box if open
      setRoleNameError(null); // Clear any error messages
    } catch (error) {
      toast.error(error.message); // Show specific error message in toast
    }
  };

  const handleRoleNameChange = (enteredValue) => {
    try {
      // Ensure enteredValue is a valid string
      if (typeof enteredValue !== "string") {
        throw new Error("Entered Value must be a string");
      }

      const trimmedEnteredValue = enteredValue.trim();
      const lowercasedEnteredValue = trimmedEnteredValue.toLowerCase();

      if (enteredValue !== trimmedEnteredValue) {
        // Leading or trailing spaces detected
        return SPACE_ERROR; // Ensure SPACE_ERROR is defined
      }

      // Check for duplicates when adding a new role
      if (
        addingId !== null &&
        roleConfigNames &&
        roleConfigNames.some(
          (name) => name.toLowerCase() === lowercasedEnteredValue
        )
      ) {
        return "Role name already exists";
      }

      // Check for duplicates when editing, but ignore if the user hasn't changed the value
      if (
        editingId !== null &&
        roleConfigNames &&
        roleConfigNames.some(
          (name) => name.toLowerCase() === lowercasedEnteredValue
        ) &&
        editLabelObj?.roleName.toLowerCase() !== lowercasedEnteredValue
      ) {
        return "Role name already exists";
      }

      return true; // No errors
    } catch (error) {
      return error?.message || "";
    }
  };

  const handleValidate = (newRoleName) => {
    try {
      // Ensure value is defined and a string
      if (typeof newRoleName !== "string") {
        throw new Error("Invalid input: value must be a string");
      }
      const emptyCheck = isEmpty(newRoleName);
      const specialCharCheck = isValueContainsSplChars(newRoleName);
      const startsWithNumberCheck = isValueStartsWithNumber(
        newRoleName,
        "Role name"
      );
      const roleNameChange = handleRoleNameChange(newRoleName);

      // Combine validation results
      const checks = [
        emptyCheck,
        specialCharCheck,
        startsWithNumberCheck,
        roleNameChange,
      ];

      // Find the first encountered error
      const firstError = checks.find((result) => result !== true);

      if (firstError) {
        setRoleNameError(firstError); // Set the first encountered error message
        return false; // Return false to indicate validation failure
      } else {
        setRoleNameError(null); // Clear the error if all checks pass
        return true; // Return true to indicate validation success
      }
    } catch (error) {
      // Optionally, set a generic error message if something goes wrong
      return false; // Return false to indicate failure in case of error
    }
  };

  // Handle manual node expansion/collapse
  const handleNodeToggle = (nodeId) => {
    try {
      // Validate nodeId before proceeding
      if (!nodeId) {
        throw new Error("Invalid nodeId");
      }

      handleCancel(); // Handle potential errors within this function as well

      setExpandedItems((prevExpanded) => {
        // If the node is already expanded, remove it from the expanded list (collapse it)
        if (prevExpanded.includes(nodeId)) {
          return prevExpanded.filter((id) => id !== nodeId);
        }
        // Otherwise, add it to the expanded list (expand it)
        return [...prevExpanded, nodeId];
      });
    } catch (error) {
      toast.error("An error occurred while toggling the node");
    }
  };

  const handleCancel = () => {
    try {
      setEditingId(null); // Close the input without saving
      setAddingId(null);
      setNewRoleName(""); // Clear the input value
      setRoleNameError(null); // Clear any error messages
    } catch (error) {
      console.error("Error in handleCancel:", error.message);
    }
  };

  const handleAddClick = (itemId) => {
    try {
      // Check if itemId is valid before proceeding
      if (!itemId) {
        throw new Error("Invalid itemId");
      }

      if (expandedItems.includes(itemId)) {
        console.log(expandedItems.includes(itemId));
        if (addingId !== itemId) {
          setRoleNameError(null);
          setEditingId(null);
          setNewRoleName("");
        }
      } else {
        handleNodeToggle(itemId);
      }
      if (itemId && editingId) {
        setAddingId(itemId);
        setEditingId(null); // Close the edit text box if open
        setNewRoleName(""); // Clear the input value
        setRoleNameError(null); // Clear any error messages
      }

      // Focus the input after updating state
      setTimeout(() => {
        if (newRoleInputRef && newRoleInputRef.current) {
          newRoleInputRef.current.focus(); // Set focus on the input
        } else {
          throw new Error("newRoleInputRef is not available");
        }
      }, 0);
    } catch (error) {
      toast.error("An error occurred while adding a role");
      setRoleNameError(null);
      setEditingId(null);
      setNewRoleName("");
    }
  };

  // Generate array of all item IDs to expand all
  const expandAllItems = () => {
    const allItemIds = Array.isArray(treeData)
      ? treeData.map((item) => item.id)
      : [];
    setExpandedItems(allItemIds); // Expand all items by id
    setAddingId(null);
    setEditingId(null); // Close the edit text box if open
    setNewRoleName(""); // Clear the input value
    setRoleNameError(null); // Clear any error messages
  };

  // Collapse all items
  const collapseAllItems = () => {
    setExpandedItems([]); // Clear the expandedItems state to collapse all nodes
  };

  const handleToggle = () => {
    if (isExpanded) {
      collapseAllItems(); // Call your collapse function
    } else {
      expandAllItems(); // Call your expand function
    }
    setIsExpanded(!isExpanded); // Toggle the expanded state
  };

  const handleInputBlur = () => {
    // Validate on blur
    handleValidate(newRoleName);
  };

  const handleInputChange = (e) => {
    try {
      const { value } = e.target;
      // Ensure value is defined and a string
      if (typeof value !== "string") {
        throw new Error("Value must be a string");
      }
      setNewRoleName(value);
      handleValidate(value); // Get validation result
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      setLoading(true);
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addRole,
        edit: ApiService.updateRole,
        delete: ApiService.deleteRole,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        ROLE_SCREEN, // Pass the screen here
        // Pass appropriate parameters based on the action type
        apiAction(action === "delete" ? formData.id : formData, token),
        action, // Pass the action performed
        fetchData // Pass fetchData to call after API action
      );
    } catch (error) {
      setLoading(false);
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (event, id) => {
    event.stopPropagation();
    try {
      Swal.fire({
        text: "Are you sure you want to delete this role?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        width: "auto",
      }).then((result) => {
        result.isConfirmed && onSubmitForm({ id }, "delete");
      });
    } catch (error) {
      console.error("Error in handleDelete:", error);
      toast.error("An error occurred while deleting the role");
    }
  };

  return {
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    roleAccess,
    roleConfigNames,
    loading,
    treeData,
    editLabel,
    setEditLabel,
    editLabelObj,
    setEditLabelObj,
    editingId,
    setEditingId,
    addingId,
    setAddingId,
    newRoleName,
    setNewRoleName,
    roleNameError,
    setRoleNameError,
    handleValidate,
    handleNodeToggle,
    expandedItems,
    handleCancel,
    handleAddClick,
    setExpandedItems,
    expandAllItems,
    handleInputBlur,
    handleInputChange,
    newRoleInputRef,
    isExpanded,
    handleToggle,
  };
};
