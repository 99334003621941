import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";

// condition fields
const Conditions = forwardRef(
  (
    {
      isSubmitted,
      setIsSubmitted,
      mode,
      RemoveConditionRow,
      row,
      conditionFields,
      getConditionOptions,
      productNames,
      validFullProductNames,
      customerNames,
      viewData,
      editItemId,
      inputStyles,
      errors1,
      handleCondition,
      isValueContainsSplChars,
      isEmpty,
      handleAddRow,
      braces,
    },
    ref
  ) => {
    const {
      control,
      setValue,
      trigger,
      clearErrors,
      formState: { errors },
    } = useForm({});
    const errorInputRefs = useRef({});

    useEffect(() => {
      if (isSubmitted) {
        setTimeout(() => {
          const firstErrorField = Object.keys(errors)[0];

          if (firstErrorField && errorInputRefs.current[firstErrorField]) {
            errorInputRefs.current[firstErrorField].focus();
          }
          setIsSubmitted(false); // Reset after focusing
        }, 0); // Use a short delay to allow state update to propagate
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted]);

    const handleRemoveConditionRow = (index, row) => {
      setValue(`field_${index}`, "");
      if (mode === "edit") {
        setValue(`conDescription_${index}`, "");
      }
      clearErrors(`field_${index}`, "");
      clearErrors(`conDescription_${index}`, "");
      RemoveConditionRow(index); // logic for removing the row
    };

    const isDuplicatedRow = (fieldName, value, rowIndex) => {
      if (!row || rowIndex >= row.length) {
        console.error(`Invalid rowIndex: ${rowIndex}`);
        return true; // or some appropriate default behavior
      }
      // Check if there are duplicate entries based on conDescription, conditionEntityName, field, and symbols
      const isDuplicate = row.some((r, index) => {
        if (index === rowIndex) return false; // Skip the current row

        const isDuplicateRow =
          r.conDescription === row[rowIndex].conDescription &&
          r.startBrace === row[rowIndex].startBrace &&
          r.conditionEntityName === row[rowIndex].conditionEntityName &&
          r.field === row[rowIndex].field &&
          r.symbols === row[rowIndex].symbols &&
          r.endBrace === row[rowIndex].endBrace;

        return isDuplicateRow;
      });
      // Return appropriate validation message or true if not duplicate
      return isDuplicate
        ? fieldName
          ? `${fieldName} must be unique`
          : "Must be unique"
        : true;
    };

    // Function to handle clearing duplicate errors based on current validation results
    const clearDuplicateErrors = (
      errors,
      isDuplicatedRow,
      field,
      value,
      rowIndex,
      clearErrors
    ) => {
      // Iterate through errors to find the key with type 'isDuplicate'
      Object.keys(errors).forEach((key) => {
        if (errors[key].type === "isDuplicate") {
          const fieldName = key.split("_")[0]; // Extract field name from key
          const rowIndex = parseInt(key.split("_")[1]); // Extract rowIndex from key
          const validationResults = isDuplicatedRow(fieldName, value, rowIndex);

          if (validationResults === true) {
            clearErrors(key);
          } else {
            // Clear the specific error field if it exists in validationResults
            if (validationResults.startsWith(fieldName)) {
              clearErrors(validationResults);
            }
          }
        }
      });

      // Clear errors if no duplicate is found in the current field
      const validationResult = isDuplicatedRow(field.label, value, rowIndex);
      if (validationResult === true) {
        // clearErrors(`conDescription_${rowIndex}`);
        clearErrors(`conditionEntityName_${rowIndex}`);
        clearErrors(`field_${rowIndex}`);
        clearErrors(`symbols_${rowIndex}`);
      }

      return validationResult;
    };
    // State to track if the dropdown is open or focused
    const [isFocused, setIsFocused] = useState(false);

    // Calculate conditionOptions for all rows and fields at once
    const allConditionOptions = useMemo(() => {
      return row.map((rows) =>
        conditionFields.map((field) =>
          getConditionOptions(field, rows.conditionEntityName)
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row, conditionFields]);

    // Memoize validateForm to ensure it doesn't change on each render
    const validateForm = useCallback(async () => {
      try {
        await trigger();
        // Combine errors
        const combinedErrors = { ...errors };
        return combinedErrors;
      } catch (error) {
        console.log(error);
      }
    }, [trigger, errors]);

    useImperativeHandle(ref, () => ({ validateForm }), [validateForm]);

    return row.map((rows, rowIndex) => (
      <Grid
        container
        spacing={1}
        key={rowIndex}
        className="bg-gray-100 border"
        mt={rowIndex > 0 ? 1 : 0}
        pt={0.5}
        pb={1.5}
      >
        {conditionFields.map((field, setIndex) => {
          // Access the memoized options for the current row and field
          const conditionOptions = allConditionOptions[rowIndex][setIndex];

          return (
            <Grid
              item
              xs={12}
              sm={field.isConditionBtn ? 2 : field.isIf ? 5.5 : 5.5}
              md={field.isConditionBtn ? 2 : field.isIf ? 3.66 : 3.66}
              lg={
                field.isConditionBtn
                  ? 1
                  : field.isIf
                  ? 0.4
                  : field.name === "startBrace"
                  ? 0.5
                  : field.name === "endBrace"
                  ? 0.5
                  : field.name === "conditionOption"
                  ? 1
                  : field.name === "symbols"
                  ? 1.3
                  : field.name === "conDescription"
                  ? 3.2
                  : 2
              }
              p={1}
              key={setIndex}
            >
              {field.name === "conDescription" ? (
                rows.field === "productName" ||
                rows.field === "fullProductName" ||
                rows.field === "customerName" ? (
                  <Controller
                    name={`${field.name}_${rowIndex}`}
                    control={control}
                    defaultValue={rows[field.name] || ""}
                    rules={{
                      required:
                        mode === "edit"
                          ? rows[field.name] === null ||
                            rows[field.name] === undefined ||
                            (rows[field.name] === "" && field.required)
                            ? `${field.label} is required`
                            : false
                          : field.required
                          ? `${field.label} is required`
                          : false,

                      validate: {
                        isDuplicate: (value) => {
                          const validationResult = clearDuplicateErrors(
                            errors,
                            isDuplicatedRow,
                            field,
                            value,
                            rowIndex,
                            clearErrors
                          );
                          return validationResult;
                        },
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={
                          rows.field === "productName"
                            ? productNames
                            : rows.field === "fullProductName"
                            ? validFullProductNames // Use the validated array
                            : customerNames
                        }
                        multiple={
                          rows.symbols === "IN" || rows.symbols === "NOT IN"
                        } // Enable multi-select
                        disableClearable
                        fullWidth
                        sx={{
                          pointerEvents:
                            viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                          "& .MuiAutocomplete-inputRoot": {
                            height: isFocused ? "auto" : "2.5rem", // Enlarge height when focused or clicked
                            minHeight: "2.5rem", // Ensure a minimum height
                            transition: "height 0.2s ease-in-out", // Smooth transition effect
                            overflowY: isFocused ? "auto" : "hidden", // Show all selected items when focused
                            whiteSpace: "nowrap", // Prevent wrapping
                            overflow: "hidden", // Hide overflow content
                            textOverflow: "ellipsis", // Display ellipsis for overflow
                            backgroundColor: "#f0f0f0", // Background color
                            borderRadius: "4px", // Rounded corners for a nicer look
                          },
                          ...inputStyles,
                        }}
                        onBlur={() => {
                          setIsFocused(false);
                          trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        }}
                        onFocus={() => setIsFocused(true)} // Set focus state
                        onOpen={() => setIsFocused(true)} // Also handle open state
                        onClose={() => setIsFocused(false)} // Reset focus when closing dropdown
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={field.label}
                            variant="outlined"
                            size="small"
                            required={field.required}
                            error={
                              !!error || !!errors1[`${field.name}_${rowIndex}`]
                            }
                            helperText={
                              error
                                ? error.message
                                : errors1[`${field.name}_${rowIndex}`]
                                ? errors1[`${field.name}_${rowIndex}`].message
                                : null
                            }
                            FormHelperTextProps={{ sx: { mb: -3 } }}
                            InputLabelProps={{
                              style: {
                                pointerEvents: "none",
                              },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                height: "2.5rem", // Fixed height for the input
                              },
                            }}
                          />
                        )}
                        value={
                          rows.symbols === "IN" || rows.symbols === "NOT IN"
                            ? Array.isArray(rows[field.name])
                              ? rows[field.name]
                              : [] // Ensure value is an array for multi-select
                            : rows[field.name] !== undefined &&
                              rows[field.name] !== ""
                            ? rows[field.name]
                            : ""
                        }
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          handleCondition(rowIndex, field.name, newValue);
                          // Update the row with the new value (array of selected items)
                          rows[field.name] = newValue;

                          // Trigger validation for both fields
                          trigger(`${field.name}_${rowIndex}`).then(() => {
                            // Check if the other field needs validation
                            if (
                              mode === "edit" &&
                              field.name === "conditionEntityName" &&
                              rows.field !== undefined &&
                              rows.field !== null &&
                              rows.field !== ""
                            ) {
                              trigger(`field_${rowIndex}`);
                            } else if (
                              field.name === "conditionEntityName" &&
                              rows.conditionEntityName !== undefined &&
                              rows.conditionEntityName !== null &&
                              rows.conditionEntityName !== ""
                            ) {
                              clearErrors(`field_${rowIndex}`); // Clear error if the value is not a duplicate
                              setValue(`field_${rowIndex}`, rows.field);
                            }
                          });
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              size="small" // Set chip size to small
                            />
                          ))
                        }
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name={`${field.name}_${rowIndex}`}
                    control={control}
                    defaultValue={rows[field.name] || ""}
                    rules={{
                      required:
                        mode === "edit"
                          ? rows[field.name] === null ||
                            rows[field.name] === undefined ||
                            (rows[field.name] === "" && field.required)
                            ? `${field.label} is required`
                            : false
                          : field.required
                          ? `${field.label} is required`
                          : false,
                      validate: {
                        specialCharacter: (value) =>
                          isValueContainsSplChars(value),
                        isEmpty: (value) =>
                          field.required &&
                          value !== "" &&
                          field.name === `conDescription`
                            ? isEmpty(value)
                            : true,
                        isDuplicate: (value) => {
                          const validationResult = clearDuplicateErrors(
                            errors,
                            isDuplicatedRow,
                            field,
                            value,
                            rowIndex,
                            clearErrors
                          );
                          return validationResult;
                        },
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        name={`${field.name}_${rowIndex}`}
                        type={field.type}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onBlur={() => {
                          trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        }}
                        label={field.label}
                        required={field.required}
                        value={rows[field.name]}
                        onChange={(e) => {
                          onChange(e.target.value);
                          handleCondition(rowIndex, field.name, e.target.value);
                          trigger(`${field.name}_${rowIndex}`);
                        }}
                        InputLabelProps={{
                          style: {
                            pointerEvents: "none",
                          },
                        }}
                        error={
                          !!error || !!errors1[`${field.name}_${rowIndex}`]
                        }
                        helperText={
                          error
                            ? error.message
                            : errors1[`${field.name}_${rowIndex}`]
                            ? errors1[`${field.name}_${rowIndex}`].message
                            : null
                        }
                        FormHelperTextProps={{ sx: { mb: -3 } }}
                        inputRef={(ref) => {
                          errorInputRefs.current[`${field.name}_${rowIndex}`] =
                            ref;
                        }}
                      />
                    )}
                  />
                )
              ) : field.isIf ? (
                <Typography variant="subtitle1" marginTop={0.5}>
                  {rowIndex === 0 ? "if(" : ""}
                </Typography>
              ) : field.name === "conditionOption" && rowIndex === 0 ? (
                <Typography variant="subtitle1" marginTop={0.5}>
                  Condition
                </Typography>
              ) : field.isConditionBtn ? (
                viewData && editItemId ? null : (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                      onClick={() => handleAddRow(rowIndex)}
                      color={"success"}
                    >
                      <Typography
                        variant="subtitle1"
                        component="span"
                        sx={{ color: "black" }}
                      >
                        {rowIndex === row.length - 1 && ")"}
                      </Typography>
                      <AddCircleOutlineTwoTone />
                    </IconButton>
                    <IconButton
                      onClick={() => handleRemoveConditionRow(rowIndex, rows)}
                      color={"error"}
                      disabled={row.length > 1 ? false : true}
                    >
                      <RemoveCircleOutlineTwoTone />
                    </IconButton>
                  </Box>
                )
              ) : (
                <Controller
                  name={`${field.name}_${rowIndex}`}
                  control={control}
                  defaultValue={rows[field.name] || ""}
                  rules={{
                    required:
                      mode === "edit"
                        ? rows[field.name] === null ||
                          rows[field.name] === undefined ||
                          (rows[field.name] === "" &&
                            field.required &&
                            field.name !== "endBrace" &&
                            field.name !== "startBrace")
                          ? field.label
                            ? `${field.label} is required`
                            : "This field is required"
                          : false
                        : null,
                    validate: {
                      isDuplicate: (value) => {
                        const validationResult = clearDuplicateErrors(
                          errors,
                          isDuplicatedRow,
                          field,
                          value,
                          rowIndex,
                          clearErrors
                        );
                        return validationResult;
                      },
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={
                        field.name === "startBrace"
                          ? braces
                          : field.name === "endBrace"
                          ? braces
                          : // : getConditionOptions
                            conditionOptions
                      }
                      fullWidth
                      disableClearable
                      onBlur={() => {
                        trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        if (mode === "edit") {
                          trigger(`field_${rowIndex}`); // Trigger validation for selection_0 as well
                        }
                      }}
                      renderOption={(props, option) => {
                        const isDisabled =
                          (field.name === "startBrace" && option === ")") ||
                          (field.name === "endBrace" && option === "(");

                        return (
                          <li
                            {...props}
                            style={{ opacity: isDisabled ? 0.5 : 1 }}
                          >
                            <span
                              style={{ color: isDisabled ? "gray" : "inherit" }}
                            >
                              {option}
                            </span>
                          </li>
                        );
                      }}
                      getOptionDisabled={(option) =>
                        (field.name === "startBrace" && option === ")") ||
                        (field.name === "endBrace" && option === "(")
                      }
                      onChange={(event, newValue) => {
                        onChange(newValue);
                        handleCondition(rowIndex, field.name, newValue);

                        // Update the rows with the new value
                        rows[field.name] = newValue;

                        // Trigger validation for both fields
                        trigger(`${field.name}_${rowIndex}`).then(() => {
                          // Check if the other field needs validation
                          if (
                            mode === "edit" &&
                            field.name === "conditionEntityName" &&
                            rows.field !== undefined &&
                            rows.field !== null &&
                            rows.field !== ""
                          ) {
                            trigger(`field_${rowIndex}`);
                          } else if (
                            field.name === "conditionEntityName" &&
                            rows.conditionEntityName !== undefined &&
                            rows.conditionEntityName !== null &&
                            rows.conditionEntityName !== ""
                          ) {
                            clearErrors(`field_${rowIndex}`); // Clear error if the value is not a duplicate
                            setValue(`field_${rowIndex}`, rows.field);
                          }
                        });
                      }}
                      style={{
                        pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          label={field.label}
                          required={field.required}
                          size="small"
                          error={
                            !!error || !!errors1[`${field.name}_${rowIndex}`]
                          }
                          helperText={
                            error
                              ? error.message
                              : errors1[`${field.name}_${rowIndex}`]
                              ? errors1[`${field.name}_${rowIndex}`].message
                              : null
                          }
                          FormHelperTextProps={{ sx: { mb: -3 } }}
                          InputLabelProps={{
                            style: {
                              pointerEvents: "none",
                            },
                          }}
                          inputRef={(ref) => {
                            errorInputRefs.current[
                              `${field.name}_${rowIndex}`
                            ] = ref;
                          }}
                        />
                      )}
                      value={
                        rows[field.name] !== undefined &&
                        rows[field.name] !== "" &&
                        rows[field.name] !== null
                          ? rows[field.name]
                          : ""
                      }
                    />
                  )}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    ));
  }
);
export default Conditions;
