import { Autocomplete, TextField } from "@mui/material";
import {
  actualEmailPattern,
  isBeyond50Words,
  isEmpty,
  preventNonDigitInput,
  preventNonNumericInput,
} from "../components/config/fieldConfig";

export function CustomAutocomplete({
  field,
  register,
  trigger,
  setValue,
  watch,
  errors,
  fieldRefs,
  onChange: parentOnChange, // Accept parent-provided onChange function
  style = {},
  InputLabelProps = {},
  ...rest
}) {
  return (
    <Autocomplete
      name={field.name}
      options={field.options}
      value={
        field.options.includes(watch(field.name))
          ? watch(field.name)
          : field.isNumber && field.options.includes(Number(watch(field.name)))
          ? Number(watch(field.name))
          : null
      }
      isOptionEqualToValue={(option, value) =>
        option === value || option.toString() === value.toString()
      }
      getOptionLabel={(option) => (option ? String(option) : "")} // Convert option to string
      // Ensure a default null value if no value is selected
      onChange={(event, value) => {
        // Default onChange logic
        setValue(field.name, value); // Update form value when an option is selected
        trigger(field.name); // Trigger validation after setting the value

        // Call the parent's onChange function if provided
        if (parentOnChange) {
          parentOnChange(event, value);
        }
      }}
      style={style}
      {...rest} // Spread additional props here
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          label={field.label}
          required={field.required}
          InputLabelProps={InputLabelProps}
          {...register(field.name, {
            required: field.required ? `${field.label} is required` : false,
            onBlur: () => trigger(field.name), // Trigger validation on blur
          })}
          error={!!errors[field.name] && !params.inputProps.value} // Check if field is empty
          helperText={
            errors[field.name]?.message &&
            (!params.inputProps.value ? errors[field.name]?.message : "")
          } // Show helper text only when there's an error and field is empty
          FormHelperTextProps={{ sx: { mb: -3 } }}
          inputRef={(el) => {
            if (el) {
              fieldRefs.current[field.name] = el;
            }
          }} // Set ref
        />
      )}
    />
  );
}

export function CustomTextField({
  field = {}, // Default to an empty object
  register = () => {}, // Default to a no-op function
  trigger = () => {}, // Default to a no-op function
  errors = {}, // Default to an empty object
  fieldRefs = { current: {} }, // Default to a ref object with an empty current property
  InputLabelProps = {}, // Default to an empty object
  InputProps = {}, // Default to an empty object
  inputProps = {}, // Default to an empty object
  validate = {}, // Default to an empty object
  style = {}, // Default style
  onChange: parentOnChange, // Accept parent-provided onChange function
  ...rest // Collect any additional props
}) {
  return (
    <TextField
      variant="outlined"
      size="small"
      margin="dense"
      fullWidth
      name={field.name || ""} // Ensure name has a default value
      label={field.label || ""} // Ensure label has a default value
      type={field.type || "text"} // Default to text if no type is provided
      required={field.required || false} // Default to false if required is not specified
      rows={field.rows || 1} // Default to 1 if rows is not specified
      multiline={field.multiline || false} // Default to false if not multiline
      autoFocus={field.autoFocus || false} // Default to false for autoFocus
      style={style}
      onWheel={(event) => {
        // Check if the input type is number to prevent scrolling
        if (field.type === "number") {
          event.target.blur(); // Prevent number field scrolling
        }
      }}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      inputProps={inputProps}
      {...rest}
      {...register(field.name || "", {
        required: field.required ? `${field.label} is required` : false,
        ...(field.type === "email" && {
          pattern: {
            value: actualEmailPattern,
            message: "Invalid email address",
          },
        }),
        validate: {
          isEmpty: (value) => (field.required ? isEmpty(value) : true),
          isBeyond50Words: (value) =>
            field.type === "textarea" ? isBeyond50Words(value) : true,
          ...validate, // Merge additional validation rules
        },
        onChange: (e, value) => {
          trigger(field.name || ""); // Trigger validation

          // Custom behavior for time picker
          if (field.type === "time") {
            setTimeout(() => {
              e.target.blur(); // Close the time picker
            }, 100);
          }

          // Call parent's onChange if provided
          if (parentOnChange) {
            parentOnChange(e, value);
          }
        },
        onBlur: () => trigger(field.name || ""), // Trigger validation on blur
      })}
      error={!!errors[field.name]}
      helperText={errors[field.name]?.message}
      FormHelperTextProps={{ sx: { mb: -3 } }}
      onKeyDown={(e) => {
        if (field.type === "number") {
          field.isNonDecimal
            ? preventNonDigitInput(e)
            : preventNonNumericInput(e); // Custom number field handling
        }
      }}
      inputRef={(el) => {
        if (el) {
          fieldRefs.current[field.name] = el; // Set the ref for the field
        }
      }}
    />
  );
}
