import { useEffect, useRef } from "react";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import "react-international-phone/style.css";
import countries from "iso-3166-1-alpha-2"; // Importing the countries object
import { validatePhoneNumber } from "../../config/fieldConfig";

export default function CountryPhone({
  name = "",
  label = "",
  type = "tel",
  required = false,
  viewData = true,
  editFormData = {}, // Default to empty object
  onPhoneNumberChange = () => {},
  register = () => ({}),
  setValue = () => {},
  trigger = () => {},
  errors = {},
}) {
  // Create a ref for the mobileNumber input field
  const phoneInputRef = useRef(null);

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "in",
      countries: defaultCountries,
    });

  // Effect to populate the form with edit data
  useEffect(() => {
    try {
      if (editFormData) {
        setValue("mobileNumber", editFormData.mobileNumber || ""); // Set default to empty string
        inputRef.current.value = editFormData.mobileNumber || ""; // Set default to empty string
      }
    } catch (error) {
      console.error("Error populating form data: ", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFormData, setValue]);

  const appendValues = () => {
    try {
      if (editFormData?.length > 0) {
        const orgData = editFormData[0]?.organization || {}; // Default to empty object

        // Populate registration fields to display data
        setValue("mobileNumber", orgData.mobileNumber || "");

        // Simulate changing the country to trigger update of inputValue
        if (orgData.country) {
          const countryCode = countries.getCode(orgData.country);

          // Set the country value, flag, and dialCode
          if (countryCode) {
            const countryInfo = {
              name: orgData.country,
              iso2: countryCode.toLowerCase(), // Convert the country code to lowercase
            };
            setCountry(countryInfo.iso2); // Passing ISO2 code directly
          } else {
            console.warn(`Country code not found for: ${orgData.country}`);
            // Set a default value if the country code is not found
            setCountry("in"); // Set default country to "in"
          }
        }
        inputRef.current.value = orgData.mobileNumber || ""; // Set default to empty string
      }
    } catch (error) {
      console.error("Error appending values: ", error);
    }
  };

  useEffect(() => {
    appendValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFormData, setValue]);

  useEffect(() => {
    try {
      trigger("mobileNumber");

      // Find the country name based on the country code
      const countryEntry = defaultCountries.find(
        (count) => count[1] === country.iso2
      );
      if (!countryEntry) {
        console.log("Country not found");
      }
    } catch (error) {
      console.error("Error in country change effect: ", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const handleCountryChange = (e) => {
    try {
      const newCountry = e.target.value;
      const oldDialCode = inputRef.current && inputRef.current.value; // Get the current dial code
      let newDialCode = "";
      defaultCountries.forEach((c) => {
        const country = parseCountry(c);
        if (country.iso2 === newCountry) {
          newDialCode = country ? country.dialCode : "";
          return;
        }
      });

      // Remove the old dial code from the mobileNumber number
      let newPhoneNumber = inputValue.replace(`+${oldDialCode}`, "");

      // If the new dial code is not empty, add it to the beginning of the mobileNumber number
      if (newDialCode !== "") {
        newPhoneNumber = `+${newDialCode}`;
      }
      // Update the mobileNumber number state
      inputRef.current.value = newPhoneNumber;

      // Update the selected country
      setCountry(newCountry);

      setValue("mobileNumber", newPhoneNumber);
      onPhoneNumberChange(newPhoneNumber); // Call the new prop here to update parent's state
      trigger("mobileNumber");
    } catch (error) {
      console.error("Error handling country change: ", error);
    }
  };

  const fieldRefs = useRef({});

  return (
    <>
      <TextField
        name={name}
        label={label}
        type={type}
        required={required}
        margin="dense"
        variant="outlined"
        size="small"
        fullWidth
        inputRef={(el) => {
          // Assign ref conditionally based on the name
          if (name === "mobileNumber" && phoneInputRef) {
            phoneInputRef.current = el;
          }

          // Also store in fieldRefs for general use
          if (el) {
            fieldRefs.current[name] = el;
          }
        }} // Combined ref logic
        InputProps={{
          ...(name === "mobileNumber"
            ? {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      marginRight: "2px",
                      marginLeft: "-8px",
                    }}
                  >
                    <Select
                      MenuProps={{
                        style: {
                          height: "300px",
                          width: "360px",
                          top: "10px",
                          left: "-34px",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                      sx={{
                        width: "max-content",
                        fieldset: {
                          display: "none",
                        },
                        '&.Mui-focused:has(div[aria-expanded="false"]) fieldset':
                          {
                            border: "none", // Ensure the border stays removed when focused
                          },
                        ".MuiSelect-select": {
                          padding: "8px",
                          paddingRight: "24px !important",
                        },
                        svg: {
                          right: 0,
                        },
                      }}
                      style={{
                        pointerEvents: viewData ? "none" : "auto",
                      }}
                      value={country.iso2}
                      onChange={handleCountryChange} // Update the phone number when country changes
                      renderValue={(value) => (
                        <FlagImage iso2={value} style={{ display: "flex" }} />
                      )}
                    >
                      {defaultCountries.map((c) => {
                        const country = parseCountry(c);
                        return (
                          <MenuItem key={country.iso2} value={country.iso2}>
                            <FlagImage
                              iso2={country.iso2}
                              style={{ marginRight: "8px" }}
                            />
                            <Typography marginRight="8px">
                              {country.name}
                            </Typography>
                            <Typography color="gray">
                              +{country.dialCode}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </InputAdornment>
                ),
                value: inputRef.current?.value || inputValue, // Use loading value or state value
                onChange: (e) => {
                  try {
                    const value = e.target.value;
                    handlePhoneValueChange(e);
                    setValue(name, value); // Update the form value
                    onPhoneNumberChange(value); // Update parent with value
                    trigger(name); // Trigger validation on change
                  } catch (error) {
                    console.error("Error updating phone number: ", error);
                  }
                },
                inputRef: inputRef,
              }
            : {}),
        }}
        {...register(name, {
          required: required ? `${label} is required` : false,
          validate: {
            validatePhoneNumber: (value) =>
              name === "mobileNumber" && editFormData
                ? validatePhoneNumber(value, country)
                : true,
          },
        })}
        InputLabelProps={{
          style: {
            pointerEvents: "none",
          },
        }}
        error={!!errors[name]}
        helperText={errors[name]?.message}
        FormHelperTextProps={{ sx: { mb: -3 } }}
        onChange={(e) => {
          try {
            setValue(name, e.target.value); // Update the form value
            onPhoneNumberChange(e.target.value); // Update parent with value
            trigger(name); // Trigger validation on change
          } catch (error) {
            console.error("Error on change event: ", error);
          }
        }}
        onBlur={() => {
          trigger(name); // Trigger validation on blur
        }}
      />
    </>
  );
}
