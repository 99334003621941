import { ADMIN_NAME } from "../../config/constants";

// Function to check if access is granted to a resource
export function hasAccessWrapper(arrayOfObjects, resource) {
  const result =
    Array.isArray(arrayOfObjects) &&
    arrayOfObjects.some(
      (item) => item.resource === resource && item.menuManagement
    );
  return result;
}

const filterSettingMenuItems = (menuItems) => {
  return menuItems
    .map((item) => {
      const filteredChildren = item.children
        ? filterSettingMenuItems(item.children)
        : null;

      // Parent should be accessible if it has accessible children or if itself is accessible
      const isParentAccessible =
        filteredChildren && filteredChildren.length > 0;

      if (item.isAccess || isParentAccessible) {
        return {
          ...item,
          children: filteredChildren,
        };
      }

      return null; // Filter out items without access
    })
    .filter((item) => item !== null);
};

// Function to generate the menu items based on roles and permissions
const menus = ({ arrayOfObjects, role, isMonitor, isSuperAdmin }) => {
  return [
    {
      text: "Dashboard",
      link: "dashboard",
      children: null,
      isAccess: hasAccessWrapper(arrayOfObjects, "Dashboard"),
    },
    {
      text: "Settings",
      link: "#",
      children: [
        {
          text: "Company Setup",
          link: "companySetup",
          isAccess: hasAccessWrapper(arrayOfObjects, "CompanySetup"),
        },
        {
          text: "Role",
          link: "role",
          isAccess: hasAccessWrapper(arrayOfObjects, "Role"),
        },
        {
          text: "Type",
          link: "type",
          isAccess: hasAccessWrapper(arrayOfObjects, "Type"),
        },
        {
          text: "Field Mapping",
          link: "fieldMapping",
          isAccess: hasAccessWrapper(arrayOfObjects, "FieldMapping"),
        },
        {
          text: "Page Access",
          link: "access",
          isAccess: role === ADMIN_NAME,
        },
      ],
    },
    {
      text: "Master",
      link: "#",
      children: [
        {
          text: "Beneficiaries",
          link: "beneficiary",
          isAccess: hasAccessWrapper(arrayOfObjects, "Beneficiary"),
        },
        {
          text: "Customers",
          link: "customer",
          isAccess: hasAccessWrapper(arrayOfObjects, "Customer"),
        },
        {
          text: "Products",
          link: "product",
          isAccess: hasAccessWrapper(arrayOfObjects, "Product"),
        },
      ],
    },
    {
      text: "Transactions",
      link: "transaction",
      children: null,
      isAccess: hasAccessWrapper(arrayOfObjects, "Transaction"),
    },
    {
      text: "Commission",
      link: "#",
      children: [
        {
          text: "Pay Frequencies",
          link: "payFrequency",
          isAccess: hasAccessWrapper(arrayOfObjects, "PayFrequency"),
        },
        {
          text: "Variables",
          link: "variable",
          isAccess: hasAccessWrapper(arrayOfObjects, "Variable"),
        },
        {
          text: "Commission Setup",
          link: "commission",
          isAccess: hasAccessWrapper(arrayOfObjects, "Commission"),
        },
        {
          text: "Assign Commissions",
          link: "assignCommission",
          isAccess: hasAccessWrapper(arrayOfObjects, "AssignCommission"),
        },
      ],
    },
    {
      text: "Scheduler",
      link: "#",
      children: [
        {
          text: "Schedule",
          link: "schedule",
          isAccess: hasAccessWrapper(arrayOfObjects, "Schedule"),
        },
        {
          text: "Schedule Status",
          link: "scheduleStatus",
          isAccess: hasAccessWrapper(arrayOfObjects, "ScheduleStatus"),
        },
      ],
    },
    {
      text: "Report",
      link: "#",
      children: [
        {
          text: "Comm Report",
          link: "report",
          isAccess: hasAccessWrapper(arrayOfObjects, "Report"),
        },
        {
          text: "ReportR",
          link: "reportR",
          isAccess: hasAccessWrapper(arrayOfObjects, "ReportR"),
        },
        {
          text: "Other Reports",
          link: "#",
          isAccess:
            hasAccessWrapper(arrayOfObjects, "CommByProduct") ||
            hasAccessWrapper(arrayOfObjects, "CommByCustomer"),
          children: [
            {
              text: "Comm By Product",
              link: "commByProduct",
              isAccess: hasAccessWrapper(arrayOfObjects, "CommByProduct"),
            },
            {
              text: "Comm By Customer",
              link: "commByCustomer",
              isAccess: hasAccessWrapper(arrayOfObjects, "CommByCustomer"),
            },
          ],
        },
      ],
    },
    {
      text: "Monitor",
      link: "monitor",
      children: null,
      isAccess: isMonitor === "true",
    },
    {
      text: "Plan",
      link: "plan",
      children: null,
      isAccess: isSuperAdmin === "true",
    },
    {
      text: "Payment History",
      link: "paymentHistory",
      children: null,
      isAccess: isSuperAdmin === "true",
    },
    {
      text: "User Activity",
      link: "userActivity",
      children: null,
      isAccess: isMonitor === "true" || isSuperAdmin === "true",
    },
    {
      text: "Tickets",
      link: "ticket",
      children: null,
      isAccess: isMonitor === "true" || isSuperAdmin === "true",
    },
  ];
};

// Static settingMenu array
const settingMenu = ({ arrayOfObjects, role }) => {
  const menuItems = [
    {
      text: "Profile",
      link: "profile",
      isAccess: true,
    },
    {
      text: "Subscription",
      link: "billing",
      isAccess: hasAccessWrapper(arrayOfObjects, "Billing"),
    },
    {
      text: "Pricing",
      link: "pricing",
      isAccess: hasAccessWrapper(arrayOfObjects, "Pricing"),
    },
    {
      text: "Audit Log",
      link: "auditLog",
      isAccess: role === ADMIN_NAME,
    },
    {
      text: "Tickets",
      link: "ticket",
      children: null,
      isAccess: hasAccessWrapper(arrayOfObjects, "Ticket"),
    },
    {
      text: "Reset Password",
      link: "/reset_pwd",
      isAccess: true,
    },
    {
      text: "Logout",
      link: "/sign_in",
      isAccess: true,
    },
  ];
  return filterSettingMenuItems(menuItems);
};

export { menus, settingMenu };
