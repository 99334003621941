import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import {
  CURRENCY_NAME,
  JWT_TOKEN,
  USER_ROLE,
} from "../../config/sessionStorage";
import {
  ADMIN_NAME,
  PRODUCT_DISPLAY_NAME,
  PRODUCT_SCREEN,
} from "../../config/constants";
import { LOADING_MSG } from "../../config/toastMessage";
import { addSerialNumber } from "../../config/fieldConfig";
import { customAddType } from "../../../utils/addTypeFunctions";
import ProductFields from "./fields";
import ProductColumns from "./columns";
import { getAccessLevels } from "../../../utils/accessLevels";
import useSubmitForm from "../../../utils/useSubmitForm";
import { getActionsColumn } from "../../../utils/actionColumn";
import { masterUploadFile } from "../../../utils/Master/fileUploadHelper";
import { addCustomFields } from "../../../utils/Master/addCustomField";
import { useDynamicFields } from "../../../utils/Master/useDynamicFields";
import { extractCustomField } from "../../../utils/Master/customFieldExtractor";
import { extractCustomFieldApiValues } from "../../../utils/Master/customFieldApiHandler";
import { formatCurrency } from "../../../utils/CurrencyFormatter";

export const ProductService = (fileInputRef) => {
  const [data, setTableData] = useState([]); // Table data state
  const [editFormOpen, setEditFormOpen] = useState(false); // Track if edit form is open
  const [editItemId, setEditItemId] = useState(null); // ID of the item being edited
  const [editFormData, setEditFormData] = useState({}); // Stores data for the edit form
  const [dynamicColumns, setDynamicColumns] = useState([]); // Dynamic columns for the table
  const [customFieldsArrayObject, setCustomFieldsArrayObject] = useState({}); // Custom fields data
  const [fieldMappingArrayObject, setFieldMappingArrayObject] = useState([]); // Field mapping array
  const [openCustomFieldDialog, setOpenCustomFieldDialog] = useState(false); // Control for custom field dialog visibility
  const [viewData, setViewData] = useState(false); // Used for viewing data
  const [isEData, setIsEData] = useState(false); // Edit mode for error records
  const [resObject, setResObject] = useState({}); // Stores response object data
  const [response, setResponse] = useState({}); // Stores the main response data
  const [fieldsLabel, setFieldsLabel] = useState([]); // Labels for form fields
  const [configObj, setConfigObj] = useState([]); // Configuration object for fields
  const [productIdCheck, setProductIdCheck] = useState([]); // Stores product ID for validation
  const [productNameCheck, setProductNameCheck] = useState([]); // Stores product name for validation
  const [productTypeOptions, setProductTypeOptions] = useState([]); // Options for product type dropdown
  const [productTypeOptionsWithId, setProductTypeOptionsWithId] = useState([]); // Product type options with IDs
  const [isErrorColor, setIsErrorColor] = useState(false); // Indicates if there's an error for coloring UI
  const [uploading, setUploadInProgress] = useState(false); // Track upload progress
  const [isUploading, setIsUploading] = useState(false); // Additional flag for upload progress
  const [isTableLoading, setIsTableLoading] = useState(true); // Tracks if table data is loading
  const [newFields, setNewFields] = useState([]); // New custom fields added
  const [open, setOpen] = useState(false); // Controls modal/dialog visibility
  const [apiEndpoint, setApiEndpoint] = useState("getProducts"); // API endpoint selection
  const [selectedOption, setSelectedOption] = useState("Get All"); // Dropdown or filter selected option

  // Reference to prevent unnecessary component remounts
  const stopRemount = useRef(true);

  // Constants for screen identifier
  const screenName = PRODUCT_SCREEN || "";
  // Constants for display screen name UI identifier
  const displayName = PRODUCT_DISPLAY_NAME || "";

  // Message to show during upload
  const uploadLoadingMsg = LOADING_MSG;

  // Get the role of the current user
  const ROLE_NAME = USER_ROLE();

  // Get the currency used in the system
  const currency = CURRENCY_NAME();

  // Function to open the add form dialog
  const addFormOpen = () => {
    try {
      setOpen(true); // Set open state to true to display add form
      formApiFetch();
    } catch (error) {
      console.error("Error opening add form:", error);
    }
  };

  // Function to close the add form dialog
  const addFormClose = () => {
    try {
      setOpen(false); // Set open state to false to hide add form
    } catch (error) {
      console.error("Error closing add form:", error);
    }
  };

  // Handles option change and sets loading state, API endpoint, and selected option
  const handleOptionChange = (option, endpoint) => {
    // Set loading state only if the option has changed
    setIsTableLoading(selectedOption !== option);
    // Update API endpoint based on the selected option
    setApiEndpoint(endpoint);
    // Set the currently selected option
    setSelectedOption(option);
    // Reset the red error color on option change
    setIsErrorColor(false);
    // Flag to trigger data fetching on next render
    stopRemount.current = true;
  };

  // Menu items with labels and associated actions for each option
  const menuItems = [
    {
      label: "Get All",
      action: () => handleOptionChange("Get All", "getProducts"),
    },
    ROLE_NAME === ADMIN_NAME && {
      label: "All Errors",
      action: () => {
        handleOptionChange("All Errors", "getErrorProducts");
        setIsErrorColor(true); // Set the navigate scenario active
      },
    },
  ].filter(Boolean);

  // Props for the toolbar menu, including items and currently selected option
  const toolbarMenuProps = { menuItems, selectedOption };

  // Function to handle current error selection
  const handleCurrentError = () => {
    handleOptionChange("All Errors", "getCurrentErrorProducts");
    setIsErrorColor(true); // Set the navigate scenario active
  };

  // Effect to sync the configuration object whenever it changes
  useEffect(() => {
    setConfigObj(configObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configObj]);

  // Effect to handle data fetching based on the API endpoint
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint]);

  // Fetches data from the API and updates table and custom field states.
  const fetchData = async (isSubmit = false) => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // If token is not available, throw an error
      if (!token) throw new Error("Token not found or invalid");

      // Fetch necessary configuration and mappings for custom fields and product types
      const { getPojo, typeData, mappingPojoRes } =
        await extractCustomFieldApiValues(
          screenName, // The screen for which data is fetched
          token, // Authorization token
          setProductTypeOptionsWithId, // Function to set product type options with ID
          setProductTypeOptions, // Function to set product type options
          setConfigObj, // Function to set configuration object
          setFieldsLabel // Function to set field labels
        );

      // Determine the effective endpoint based on the isSubmit flag and current apiEndpoint
      const effectiveEndpoint =
        isSubmit && apiEndpoint === "getCurrentErrorProducts"
          ? "getProducts"
          : apiEndpoint;

      // Set error color to false only if this is a submit request and the endpoint was modified
      if (isSubmit && effectiveEndpoint === "getProducts") {
        setIsErrorColor(false);
        setSelectedOption("Get All");
      }

      // Make an API request to the specified endpoint using the token
      const response = await ApiService[effectiveEndpoint](token);

      // Extract product data from the API response
      const productData = response?.data;

      // Check if the response status is 200 (OK) and if the data is an array
      if (response?.status !== 200 || !Array.isArray(response?.data)) {
        throw new Error("Invalid response format or status");
      }

      // Map through the product data and replace productTypeId with the product type name
      const updatedTransferData = productData?.map((transfer) => {
        // Find the product type that matches the productTypeId
        const matchedProductType = typeData?.find(
          (productType) => productType.id === parseInt(transfer.productTypeId)
        );

        // Return the transfer data with updated product type or the original if not found
        return {
          ...transfer, // Keep all other data unchanged
          productType: matchedProductType
            ? matchedProductType.typeValue // Use the product type name
            : transfer.productType, // Or keep the original productType
        };
      });

      // Update the table data with the modified product data
      setTableData(updatedTransferData);

      // Process and extract additional custom field data for display or use
      extractCustomField(
        productData, // Pass product data to the function
        getPojo, // Pass POJO data
        mappingPojoRes, // Pass mapping data
        setResponse, // Set the API response data
        setResObject, // Set the response object
        setDynamicColumns, // Set dynamic columns for the table
        setCustomFieldsArrayObject, // Set custom fields array
        setFieldMappingArrayObject // Set field mapping array
      );
    } catch (error) {
      // Log any errors that occur during the process
      console.error("Error fetching data:", error);

      // Reset various states if an error occurs to avoid displaying incorrect data
      setTableData([]); // Clear table data
      setResponse([]); // Clear API response data
      setResObject({}); // Reset response object
      setDynamicColumns([]); // Clear dynamic columns
      setCustomFieldsArrayObject([]); // Clear custom fields array
      setFieldMappingArrayObject([]); // Clear field mapping array
      setProductTypeOptionsWithId([]); // Clear product type options with ID
      setProductTypeOptions([]); // Clear product type options
      setConfigObj({}); // Reset configuration object
      setFieldsLabel([]); // Clear field labels
    } finally {
      // Whether the request succeeded or failed, stop the table loading spinner
      setIsTableLoading(false);
    }
  };

  // To fetch product data
  const formApiFetch = async () => {
    try {
      // Fetch JWT token for authorization
      const token = JWT_TOKEN();

      // Check if the token is not available
      if (!token) throw new Error("Token not found or invalid");

      // Fetch all product data using the API service
      const response = await ApiService.getProducts(token);

      // Check if the response status is 200 and if the data is an array
      if (response?.status === 200 && Array.isArray(response?.data)) {
        // Extract product IDs from the response data
        const prodId = response?.data?.map((product) => product.productId);
        // Set the state with the fetched product IDs
        setProductIdCheck(prodId);

        // Extract product name from the response data
        const prodName = response?.data?.map((product) => product.productName);
        // Set the state with the fetched product name
        setProductNameCheck(prodName);
      } else {
        // If response is not 200 or data is not an array, set an empty array
        setProductIdCheck([]);
        setProductNameCheck([]);
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error fetching product data:", error?.message);
      // Optionally, set the product IDs & name to an empty array if an error occurs
      setProductIdCheck([]);
      setProductNameCheck([]);
    }
  };

  // Get dynamic form fields using the custom fields and config
  const dynamicFormFields = useDynamicFields(
    customFieldsArrayObject, // Custom fields from the user
    fieldMappingArrayObject, // Field mapping configurations
    configObj // Additional settings for the fields
  );

  // Function to handle adding a custom field
  const handleAddCustomField = (customFieldData) => {
    // Call addCustomFields to add the new custom field
    addCustomFields(
      customFieldData, // Data for the custom field
      fieldsLabel, // List of existing labels
      dynamicFormFields, // Current dynamic form fields
      newFields, // Custom fields that have been added
      setNewFields, // Function to update new fields
      setOpenCustomFieldDialog // Function to close the dialog
    );
  };

  // Function to handle add product type
  const addProductType = async (value, setValue) => {
    await customAddType(value, setValue, setProductTypeOptions, "productType");
  };

  // Function to handle file upload for import
  const handleUploadFile = (event) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Call masterUploadFile and wait for it to complete
        await masterUploadFile(
          event,
          fileInputRef, // Reference to the file input element
          screenName, // Screen name for the upload context
          fetchData, // Function to fetch updated data after upload
          setIsUploading, // Function to toggle loading state
          handleCurrentError, // Function to handle any error during the process
          reject, // Reject the promise on failure
          resolve // Resolve the promise on success
        );
      } catch (error) {
        // Log any error that occurs during the file upload process
        console.error("File upload failed:", error);
      }
    });
  };

  // Function to handle edit button click
  const handleEdit = (id, viewData) => () => {
    try {
      // Check if a valid ID is provided
      if (!id) {
        throw new Error(`Invalid ID provided for editing: ${id}`);
      }
      // Check if data is an array and has elements
      if (!Array.isArray(data) || data?.length === 0) {
        throw new Error("Data is not available or empty");
      }
      // Find the row data based on the provided ID
      const rowData = data?.find((row) => row?.id === id);
      // Check if row data is found
      if (!rowData) {
        throw new Error(`No data found for the ID: ${id}`);
      }
      // Set the edit form state and populate with row data
      setEditItemId(id); // Set the edit form state and data
      setEditFormOpen(true);
      setEditFormData(rowData); // Set edit form data
      setViewData(viewData);
      setIsEData(isErrorColor);
      formApiFetch();
    } catch (error) {
      console.error(error); // Log error to console
      toast.error(`Error editing item: ${error?.message}`); // Display the exact error message in a toast
    }
  };

  // Get fields from fields, default to empty array if not an array
  const fields = Array.isArray(
    ProductFields({ dynamicFormFields, newFields, productTypeOptions })
  )
    ? ProductFields({ dynamicFormFields, newFields, productTypeOptions })
    : [];

  // Get form submission function and loading state from the custom hook
  const { onSubmitForm, submitLoading } = useSubmitForm({
    screenName,
    fetchData: () => fetchData(true), // Pass `true` to fetchData for submission-specific logic
  });

  // Configure actions column for the data grid
  const actionsColumn = getActionsColumn({
    screenName, // Screen name for reference
    handleEdit, // Function to handle edit action
    onSubmitForm, // Function to handle form submission
  });

  // Call ProductColumns once and store the result
  const columnsResult = ProductColumns({
    actionsColumn,
    dynamicColumns,
    isErrorColor,
    handleEdit,
    currency,
  });

  // Check if the result is an array
  const columns = Array.isArray(columnsResult) ? columnsResult : [];

  // Function to format fields and add serial numbers
  const processTableData = (data) =>
    Array.isArray(data)
      ? addSerialNumber(
          data.map((item) => ({
            ...item,
            productCost: formatCurrency(item.productCost),
            productPrice: formatCurrency(item.productPrice),
            commissionRate: formatCurrency(item.commissionRate),
            commissionAmount: formatCurrency(item.commissionAmount),
            discountAmount: formatCurrency(item.discountAmount),
            discountPercentage: formatCurrency(item.discountPercentage),
          }))
        )
      : [];

  // Add serial numbers to the rows for displaying in the table
  const rows = processTableData(data ?? []);

  // Get access levels for the specified resource using a utility function
  const { writeAccess } = getAccessLevels(screenName);

  // Memoize the column visibility model to avoid recreating it on every render
  const columnVisibilityModel = useMemo(
    () => ({
      // Set initial visibility for standard columns to false (hidden)
      fullProductName: false,
      productDescription: false,
      subProductOf: false,
      commissionRate: false,
      productLevel: false,
      accountName: false,
      discountAmount: false,
      // Set visibility for dynamic columns to false (hidden)
      ...dynamicColumns.reduce((acc, column) => {
        acc[column.field] = false; // Hide each dynamic column
        return acc; // Return the accumulator
      }, {}),
    }),
    [dynamicColumns] // Recalculate if dynamicColumns changes
  );

  // Define properties for the toolbar import component
  const toolbarImportProps = {
    handleUploadFile, // Function to handle file upload
    fileInputRef, // Reference to the file input element
    uploading, // State to track if a file is currently uploading
    setUploadInProgress, // Function to update upload progress state
  };

  return {
    screenName, // Current screen name
    displayName, // UI display name
    uploadLoadingMsg, // Upload loading message
    rows, // Table data rows
    columns, // Table column configuration
    fields, // Product form fields
    writeAccess, // User write access flag
    toolbarMenuProps, // Toolbar menu properties
    toolbarImportProps, // Import toolbar properties
    open, // Dialog/modal open state
    addFormOpen, // Add form visibility
    addFormClose, // Function to close add form
    editFormOpen, // Edit form visibility
    editItemId, // ID of the item being edited
    editFormData, // Data for the edit form
    setEditFormOpen, // Function to set edit form state
    viewData, // Read-only view flag
    setViewData, // Function to set viewData state
    onSubmitForm, // Form submission handler
    submitLoading, // Submission loading state
    dynamicFormFields, // Dynamically changing form fields
    handleAddCustomField, // Add custom field function
    openCustomFieldDialog, // Custom field dialog visibility
    setOpenCustomFieldDialog, // Function to set custom field dialog state
    columnVisibilityModel, // Column visibility management
    apiEndpoint, // API endpoint for products
    isUploading, // Upload in progress flag
    isTableLoading, // Table loading state
    isErrorColor, // Error styling flag
    isEData, // Error data flag
    resObject, // Response object from API
    response, // API response data
    addProductType, // Function to add product type
    productTypeOptions, // Available product type options
    productTypeOptionsWithId, // Product types with IDs
    productIdCheck, // Customer ID check state
    productNameCheck, // Product name check state
    setNewFields,
  };
};
