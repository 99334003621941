import { createElement, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ApiService from "../apis/ApiService";
import {
  ADMIN_NAME,
  ASSIGN_COMMISSION_SCREEN,
  BENEFICIARY_SCREEN,
  COMMISSION_SCREEN,
  COMPANY_SETUP_SCREEN,
  CUSTOMER_SCREEN,
  DASHBOARD_SCREEN,
  FIELD_MAPPING_SCREEN,
  PAY_FREQUENCY_SCREEN,
  PRICING_SCREEN,
  PRODUCT_SCREEN,
  REPORT_R_SCREEN,
  REPORT_SCREEN,
  ROLE_SCREEN,
  SCHEDULE_SCREEN,
  SCHEDULE_STATUS_SCREEN,
  BILLING_SCREEN,
  TICKET_SCREEN,
  TRANSACTION_SCREEN,
  TYPE_SCREEN,
  VARIABLE_SCREEN,
  COMMISSION_BY_PRODUCT_SCREEN,
  COMMISSION_BY_CUSTOMER_SCREEN,
} from "../components/config/constants";
import {
  EMAIL_ID,
  IP_ADDRESS,
  IS_MONITOR,
  IS_SUPER_ADMIN,
  SESSION_ID,
  USER_ROLE,
} from "../components/config/sessionStorage";
import { events, excludedPaths } from "../components/config/fieldConfig";

// Public Routes
import ForgotPwd from "../components/pages/ForgotPwd";
import Landing from "../components/pages/Landing";
import RecoverPwd from "../components/pages/RecoverPwd";
import Register from "../components/pages/Register";
import ResetPwd from "../components/pages/ResetPwd";
import SignIn from "../components/pages/SignIn";
import SignUp from "../components/pages/SignUp";
import TermsConditions from "../components/pages/Terms";

// Message Routes
import AccessDenied from "../components/MessageScreens/accessDenied";
import ForgotSuccess from "../components/MessageScreens/forgotSuccess";
import RegisterSuccess from "../components/MessageScreens/registerSuccess";
import SignUpSuccess from "../components/MessageScreens/signupSuccess";
import TokenVerified from "../components/MessageScreens/tokenVerified";
import VerifyToken from "../components/MessageScreens/verifyToken";
import SessionLogout from "../components/MessageScreens/sessionLogout";

// Error Routes
import Error400 from "../components/ErrorScreens/error400";
import Error403 from "../components/ErrorScreens/error403";
// import Error404 from "../components/ErrorScreens/error404";
import Error500 from "../components/ErrorScreens/error500";
import Error503 from "../components/ErrorScreens/error503";
import UncaughtError from "../components/ErrorScreens/uncaughtError";

import HorizontalLayout from "../components/layouts/horizontal";
import VerticalLayout from "../components/layouts/vertical";
import LandingPage from "../components/pages/Landing";
import PaymentSuccess from "../components/MessageScreens/paymentSuccess";
import PaymentFailed from "../components/MessageScreens/paymentFailed";
import SessionExpired from "../components/MessageScreens/sessionExpired";
import DemoUserInfo from "../components/MessageScreens/demoUserInfo";

// Private Routes
import Access from "../components/pages/Access";
import Monitor from "../components/pages/Monitor";

import AssignCommission from "../components/pages/AssignCommission";
import Beneficiary from "../components/pages/Beneficiary";
import FieldMapping from "../components/pages/FieldMapping";
import Commission from "../components/pages/Commission";
import CompanySetup from "../components/pages/CompanySetup";
import Dashboard from "../components/pages/Dashboard";
import Customer from "../components/pages/Customer";
import PayFrequency from "../components/pages/PayFrequency";
import Product from "../components/pages/Product";
import Report from "../components/pages/Report";
import ReportR from "../components/pages/ReportR";
import Role from "../components/pages/Role";
import Schedule from "../components/pages/Schedule";
import Transaction from "../components/pages/Transaction";
import Variables from "../components/pages/Variable";
import Type from "../components/pages/Type";
import Ticket from "../components/pages/Ticket";
import Billing from "../components/pages/Billing";
import Pricing from "../components/pages/Pricing";
import MfaVerify from "../components/pages/SignIn/MfaVerify";
import IndividualDashboard from "../components/pages/IndividualDashboard";
import Plan from "../components/pages/Plan";
import PaymentHistory from "../components/pages/PaymentHistory";
import ScheduleStatus from "../components/pages/ScheduleStatus";
import AuditLog from "../components/pages/AuditLog";
import UserActivity from "../components/pages/UserActivity";
import Profile from "../components/pages/Profile";
import CommByProduct from "../components/pages/CommByProduct";
import CommByCustomer from "../components/pages/CommByCustomer";

export const resourceComponents = {
  [ASSIGN_COMMISSION_SCREEN]: AssignCommission,
  [BENEFICIARY_SCREEN]: Beneficiary,
  [FIELD_MAPPING_SCREEN]: FieldMapping,
  [COMMISSION_SCREEN]: Commission,
  [COMPANY_SETUP_SCREEN]: CompanySetup,
  Dashboard,
  [CUSTOMER_SCREEN]: Customer,
  [PAY_FREQUENCY_SCREEN]: PayFrequency,
  [PRODUCT_SCREEN]: Product,
  [REPORT_SCREEN]: Report,
  [REPORT_R_SCREEN]: ReportR,
  [COMMISSION_BY_PRODUCT_SCREEN]: CommByProduct,
  [COMMISSION_BY_CUSTOMER_SCREEN]: CommByCustomer,
  [ROLE_SCREEN]: Role,
  [SCHEDULE_SCREEN]: Schedule,
  [SCHEDULE_STATUS_SCREEN]: ScheduleStatus,
  [TRANSACTION_SCREEN]: Transaction,
  [VARIABLE_SCREEN]: Variables,
  [TYPE_SCREEN]: Type,
  [TICKET_SCREEN]: Ticket,
  [BILLING_SCREEN]: Billing,
  [PRICING_SCREEN]: Pricing,
};

function AllRouter() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Initialize isAuthenticated from sessionStorage
    return sessionStorage.getItem("isAuthenticated") === "true";
  });
  const [res, setRes] = useState(() => {
    // Initialize response from sessionStorage
    return sessionStorage.getItem("response") === "JWT token validated.";
  });

  const [userRoleAccess, setUserRoleAccess] = useState(() => {
    const roleAccess = sessionStorage.getItem("UserRoleAccess");
    // Parse the string back to an array of objects or provide an empty array as a default
    // return JSON.parse(roleAccess) || [];
    return roleAccess;
  });

  useEffect(() => {
    // Update sessionStorage when isAuthenticated changes
    sessionStorage.setItem("isAuthenticated", isAuthenticated);
    // Convert userRoleAccess to JSON string before storing it in sessionStorage
    sessionStorage.setItem("UserRoleAccess", userRoleAccess);
    sessionStorage.setItem("res", res);
  }, [isAuthenticated, res, userRoleAccess]);

  let arrayOfObjects = Array.isArray(JSON.parse(userRoleAccess))
    ? JSON.parse(userRoleAccess)
    : [];

  const role = USER_ROLE();
  const isMonitor = IS_MONITOR();
  const isSuperAdmin = IS_SUPER_ADMIN();

  // Merge initialResourceComponents with role-dependent Dashboard
  const resource = {
    ...resourceComponents,
    Dashboard: role === ADMIN_NAME ? Dashboard : IndividualDashboard,
  };

  // Private Routes
  function componentMapping(arrayOfObjects) {
    try {
      const mapping = {};

      // Ensure arrayOfObjects is an array
      if (!Array.isArray(arrayOfObjects)) {
        console.error("arrayOfObjects is not an array or is undefined");
        return mapping;
      }

      Object.entries(resource).forEach(([resource, Component]) => {
        mapping[resource] = arrayOfObjects.find(
          (obj) => obj.resource === resource && obj.readAccess
        )
          ? Component
          : null;
      });
      return mapping;
    } catch (error) {
      console.error("Error in componentMapping:", error);
      return {};
    }
  }

  const generateRoutes = () => {
    if (arrayOfObjects.length === 0) {
      arrayOfObjects = [
        {
          resource: DASHBOARD_SCREEN,
          menuManagement: true,
          readAccess: true,
          writeAccess: true,
          deleteAccess: true,
        },
      ];
    }

    const mappedComponents = componentMapping(arrayOfObjects);

    return (
      Array.isArray(arrayOfObjects) &&
      arrayOfObjects.map((route, index) => {
        const { resource, menuManagement } = route;
        const routePath = resource.toLowerCase();
        const Component = mappedComponents[resource]; // Retrieve the component from mappedComponents

        // Check if the component exists and menuManagement is true
        if (Component && menuManagement) {
          return (
            <Route
              key={index}
              index
              path={routePath}
              element={createElement(Component)}
            />
          );
        } else {
          return null; // Return null for routes with missing components or menuManagement is false
        }
      })
    );
  };

  const navigate = useNavigate();

  // Session Data
  const email = EMAIL_ID();
  const session_id = SESSION_ID();
  const ip_address = IP_ADDRESS();

  let inactivityTimer;
  let countdownInterval;
  let toastId;
  // const initialDelay = 10000; // 10 seconds
  // const countdownDuration = 10; // 10 seconds
  // const initialDelay = 60000; // 1 min or 60 seconds
  // const countdownDuration = 60; // 60 seconds
  const initialDelay = 1800000; // 30 min
  const countdownDuration = 60; // 60 seconds
  let remainingTime = countdownDuration;
  let countdownStarted = false;
  let startTime = null;

  const showToast = () => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    toastId = toast(
      `You will be logged out in ${Math.ceil(remainingTime)} seconds`,
      {
        id: toastId,
        duration: Infinity, // Ensure toast stays until explicitly dismissed
      }
    );
  };

  const updateCountdown = async () => {
    const currentTime = performance.now();
    const elapsed = (currentTime - startTime) / 1000; // Convert milliseconds to seconds
    remainingTime = countdownDuration - elapsed;

    if (remainingTime <= 0) {
      const sessionLogoutValues = {
        email: email,
        sessionId: session_id,
        ipAddress: ip_address,
      };

      // Call logoutSessionUser API
      const logoutRes = await ApiService.logoutSessionUser(sessionLogoutValues);

      // Check if the current path is in the excluded paths array
      if (excludedPaths.includes(window.location.pathname)) return;

      // Check if logout was successful
      if (logoutRes?.status === 200) {
        console.error("Failed to log out existing session.");
        toast.dismiss(); // Ensure toast is removed on logout
        sessionStorage.clear(); // Clear session storage
        navigate("/session_expired"); // Redirect to sign-in page
        clearInterval(countdownInterval);
        return;
      }
    }
    showToast();
  };

  const handleLogoutTimer = () => {
    inactivityTimer = setTimeout(() => {
      if (!countdownStarted) {
        startTime = performance.now(); // Initialize start time
        remainingTime = countdownDuration;
        countdownStarted = true;
        showToast();

        countdownInterval = setInterval(updateCountdown, 1000); // Update countdown every second
      }
    }, initialDelay);
  };

  const resetTimer = () => {
    clearTimeout(inactivityTimer);
    clearInterval(countdownInterval);
    countdownStarted = false;
    remainingTime = countdownDuration;
    startTime = performance.now(); // Reset start time

    if (toastId) {
      toast.dismiss(toastId);
      toastId = null;
    }
    handleLogoutTimer(); // Restart the timer
  };

  useEffect(() => {
    if (excludedPaths.includes(window.location.pathname)) return;

    events.forEach((event) => window.addEventListener(event, resetTimer));

    handleLogoutTimer();

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearTimeout(inactivityTimer);
      clearInterval(countdownInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedPaths]);

  return (
    <>
      <Routes>
        {/* Private Routes */}
        {isAuthenticated && res ? (
          <>
            <Route
              element={
                <HorizontalLayout
                  setIsAuthenticated={setIsAuthenticated}
                  setRes={setRes}
                  arrayOfObjects={arrayOfObjects}
                  role={role}
                  isMonitor={isMonitor}
                  isSuperAdmin={isSuperAdmin}
                />
              }
            >
              {generateRoutes()}
              <Route
                path="/auditLog"
                element={role === ADMIN_NAME ? <AuditLog /> : null}
              />
              <Route
                path="/access"
                element={role === ADMIN_NAME ? <Access /> : null}
              />
              <Route
                path="/monitor"
                element={isMonitor === "true" ? <Monitor /> : null}
              />
              <Route
                path="/plan"
                element={isSuperAdmin === "true" ? <Plan /> : null}
              />
              <Route
                path="/ticket"
                element={
                  isMonitor === "true" || isSuperAdmin === "true" ? (
                    <Ticket />
                  ) : null
                }
              />
              <Route
                path="/paymentHistory"
                element={isSuperAdmin === "true" ? <PaymentHistory /> : null}
              />
              <Route
                path="/userActivity"
                element={
                  isMonitor === "true" || isSuperAdmin === "true" ? (
                    <UserActivity />
                  ) : null
                }
              />
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route path="/reset_pwd" element={<ResetPwd />} />
          </>
        ) : (
          <>
            {/* Redirect for multiple paths */}
            <Route path="/signin" element={<Navigate to="/sign_in" />} />
            <Route path="/billing" element={<Navigate to="/sign_in" />} />
          </>
        )}

        {/* Public Routes */}
        <Route path="/" element={<Outlet />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/access_denied" element={<AccessDenied />} />
          <Route path="/session_logout" element={<SessionLogout />} />
          <Route path="/session_expired" element={<SessionExpired />} />
          <Route
            path="/demoUser_info"
            element={
              <DemoUserInfo
                setIsAuthenticated={setIsAuthenticated}
                setRes={setRes}
              />
            }
          />
          <Route path="/uncaught_error" element={<UncaughtError />} />
          <Route path="/register" element={<Register />} />
          <Route path="verify_token" element={<VerifyToken />} />
          <Route path="error_400" element={<Error400 />} />
          <Route path="error_403" element={<Error403 />} />
          <Route path="error_500" element={<Error500 />} />
          <Route path="error_503" element={<Error503 />} />
          <Route path="forgot_pwd" element={<ForgotPwd />} />
          <Route path="forgot_success" element={<ForgotSuccess />} />
          <Route path="landing" element={<Landing />} />
          <Route path="recover_pwd" element={<RecoverPwd />} />
          <Route path="register_success" element={<RegisterSuccess />} />
          <Route path="signup_success" element={<SignUpSuccess />} />
          <Route path="/payment_success" element={<PaymentSuccess />} />
          <Route path="/payment_failed" element={<PaymentFailed />} />
          <Route
            path="/sign_in"
            element={
              <SignIn
                setIsAuthenticated={setIsAuthenticated}
                setRes={setRes}
                setUserRoleAccess={setUserRoleAccess}
              />
            }
          />
          <Route
            path="/mfa-verify"
            element={
              <MfaVerify
                setIsAuthenticated={setIsAuthenticated}
                setRes={setRes}
                setUserRoleAccess={setUserRoleAccess}
              />
            }
          />
          <Route path="sign_up" element={<SignUp />} />
          <Route path="terms_conditions" element={<TermsConditions />} />
          <Route path="/token_verified" element={<TokenVerified />} />
          <Route path="vertical" element={<VerticalLayout />} />
        </Route>

        <Route path="*" element={<AccessDenied />} />
      </Routes>
    </>
  );
}

export default AllRouter;
