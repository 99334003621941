import { useEffect } from "react";
import { Button } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";
import { FILE_UPLOAD_TIME } from "../../components/config/sessionStorage";
import { useToolbarConfig } from "./ToolbarMap";
import { VisuallyHiddenInput } from "../../components/styles/style";
import { getAccessLevels } from "../accessLevels";

// Main component
export default function ImportButton({
  screenName = "",
  handleUploadFile,
  fileInputRef,
  uploading,
  setUploadInProgress,
}) {
  const Time = FILE_UPLOAD_TIME();

  const isTimeBoolean = Time === "false" ? false : true;

  // Choose the service based on the provided mode
  const service = handleUploadFile;

  useEffect(() => {
    if (sessionStorage.getItem("isFileUpload") === "true") {
      setTimeout(() => {
        if (sessionStorage.getItem("isHovering") === "Not") {
          sessionStorage.setItem("isFileUpload", false);
        }
      }, 5000); // Simulate 5 second delay
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!screenName) {
    return null;
  }

  const { writeAccess } = getAccessLevels(screenName);

  const isDisplay =
    useToolbarConfig[screenName]?.showImportButton && writeAccess;

  // show view based on screenName
  if (!isDisplay) {
    return null;
  }

  return (
    <Button
      component="label" // Renders button as label to trigger file input
      variant="text"
      size="small"
      startIcon={<FileUploadOutlined />}
      disabled={isTimeBoolean ? isTimeBoolean : uploading} // Disable button when uploading is true
    >
      Import
      {/* Visually hidden file input */}
      <VisuallyHiddenInput
        type="file"
        ref={fileInputRef}
        onChange={(e) => {
          setUploadInProgress(true); // Set uploading state to true
          service(e)
            .then(() => setUploadInProgress(false))
            .catch(() => setUploadInProgress(false));
        }}
      />
    </Button>
  );
}
