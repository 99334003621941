import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  DialogActions,
  Grid,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteTwoTone,
  EditTwoTone,
  FileUploadOutlined,
} from "@mui/icons-material";
import SimpleImageViewer from "react-simple-image-viewer";
import JoditEditor from "jodit-react";
import { transformHTML } from "../../config/fieldConfig";
import {
  assignedToOption,
  card,
  formatCurrentDateTime,
  statusOption,
} from "./config";

export default function TicketForm({
  mode,
  editItemId,
  fields,
  editFormData,
  onAddForm,
  onEditForm,
  selectedImage,
  handleFileChangeLogo,
  handleAvatarClick,
  fileInputRef,
  resourceNames,
  onClose,
  viewData,
  setViewData,
  setSelectedImage,
  isSuperAdmin,
  isMonitor,
  userName,
  orgName,
  setOrgId,
  setShowForm,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
    setError,
    control,
    reset,
    watch,
    clearErrors,
  } = useForm();

  const [selectedUserName, setSelectedUserName] = useState("");
  // For image
  const [anchorEl, setAnchorEl] = useState(null);
  const [replaceIndex, setReplaceIndex] = useState(null); // State to track the index of the image to be replaced
  // eslint-disable-next-line no-unused-vars
  const [currentIndex, setCurrentIndex] = useState(0); // Store the index of the clicked image
  const [claOpen, setClaOpen] = useState(false);

  const open = Boolean(anchorEl);

  useEffect(() => {
    reset();
    if (mode !== "edit") {
      setViewData();
      setSelectedImage([]);
      setOrgId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Check if editFormData exists
      if (editFormData) {
        setValue("description", editFormData.description);
        setValue("history", editFormData.history);
        setValue("screenName", editFormData.screenName);
        // Function to format the history array

        // Setting values for each field
        fields.forEach((field) => {
          // For other fields, set directly
          setValue(field.name, editFormData[field.name]);
        });
        setSelectedUserName(editFormData.userName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const joditConfig = useMemo(
    () => ({
      readonly: isMonitor || isSuperAdmin ? true : viewData,
      removeButtons: ["fullsize"], // Remove the "fullSize" button
      limitChars: 1000,
      addNewLine: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewData] // The empty dependency array ensures this object is created only once
  );

  const onSubmit = (formData) => {
    try {
      const currentDateTime = formatCurrentDateTime(); // Call the function to get the current date and time

      let historyMaintain;
      if (mode === "add") {
        formData.status = "New";
        const stripHtmlTags = (html) => {
          const div = document.createElement("div");
          div.innerHTML = html;
          return div.innerText || div.textContent || "";
        };

        historyMaintain = [
          {
            assignedTo: "",
            clarification: stripHtmlTags(formData.description),
            userName: userName,
            time: currentDateTime,
            orgName: orgName,
            status: "New",
            replyedUser: userName,
          },
        ];
      } else {
        historyMaintain = [
          // Safe check for both editFormData and history
          {
            assignedTo: formData.assignedTo,
            clarification: formData.conversation,
            userName: selectedUserName,
            time: currentDateTime,
            orgName: editFormData.orgName,
            status: formData.status,
            replyedUser: isMonitor
              ? "Monitor"
              : isSuperAdmin
              ? "SuperAdmin"
              : selectedUserName,
          },
          ...((editFormData && editFormData.history) || []),
        ];
      }

      // format the style for editor
      const descriptionHTMLFormate = transformHTML(formData.description);
      formData.description = descriptionHTMLFormate;
      formData.history = historyMaintain;

      delete formData.conversation;

      if (mode === "add") {
        onAddForm(formData);
      } else if (mode === "edit") {
        formData.id = editItemId;
        onEditForm(formData);
      }
      onClose();
      setShowForm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePopoverOpen = (event, index) => {
    try {
      if (!isSuperAdmin || !isMonitor) {
        setAnchorEl(event.currentTarget);
        setReplaceIndex(index); // Set the index of the image to be replaced
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const handleDownload = async (url, fileName) => {
    try {
      console.log(url);
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  const handleDeleteFile = (index) => {
    setSelectedImage((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Utility function to strip HTML tags and check for meaningful content
  const hasTextContent = (htmlString) => {
    const strippedString = htmlString.replace(/<[^>]*>?/gm, "").trim(); // Removes all HTML tags and trims
    return strippedString.length > 0; // Check if there's any non-whitespace content
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        mt={mode === "edit" ? 1 : -5}
      >
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sm={
                field.isEditor ||
                field.isHistory ||
                field.name === "conversation"
                  ? 12
                  : 6
              }
            >
              {(field.isSubtitle ||
                (isMonitor &&
                  isSuperAdmin &&
                  editFormData?.description &&
                  hasTextContent(editFormData.description))) && (
                <Typography variant="subtitle1" gutterBottom>
                  {field.subtitle}
                </Typography>
              )}

              {field.isEditor ? (
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState }) =>
                    !isMonitor && !isSuperAdmin ? (
                      <>
                        <JoditEditor
                          value={field.value}
                          config={joditConfig}
                          onChange={(content) => {
                            // Remove HTML tags
                            let plainText = content.replace(/<[^>]*>/g, "");

                            // Convert HTML entities to plain text
                            plainText = plainText
                              .replace(/&nbsp;/g, " ")
                              .replace(/&[a-z]+;/gi, "");

                            // Remove all spaces to count characters without spaces
                            const plainTextWithoutSpaces = plainText.replace(
                              /\s+/g,
                              ""
                            );

                            // Check if content length is within the limit
                            if (plainTextWithoutSpaces.length <= 1000) {
                              field.onChange(content); // Update content if within the limit
                              clearErrors("description");
                            } else {
                              field.onChange(content); // Update content even if limit is exceeded
                              setError("description", {
                                type: "manual",
                                message:
                                  "Content cannot exceed 1000 characters.",
                              });
                            }
                          }}
                        />
                        {fieldState.error && (
                          <span style={{ color: "red" }}>
                            {fieldState.error.message}
                          </span>
                        )}
                      </>
                    ) : (
                      <Typography
                        sx={{ textIndent: 15 }}
                        dangerouslySetInnerHTML={{
                          __html: editFormData?.description || "",
                        }}
                      ></Typography>
                    )
                  }
                />
              ) : field.isHistory ? (
                <Controller
                  name="history"
                  control={control}
                  render={({ field, fieldState }) =>
                    mode !== "add" ? (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setClaOpen(true);
                            }}
                            disabled={viewData}
                          >
                            Reply
                          </Button>
                        </Grid>

                        {/* Loop through field.value and render a card for each object */}
                        <Grid item xs={12} sm={12} md={12}>
                          {field?.value?.length > 0 ? (
                            <Card
                              variant="outlined"
                              sx={{
                                minHeight: "200px",
                                maxHeight: "150px",
                                overflow: "auto",
                              }}
                            >
                              {card(field.value)}
                            </Card>
                          ) : (
                            "No card data available"
                          )}
                        </Grid>
                      </Grid>
                    ) : null
                  }
                />
              ) : (
                  isMonitor || isSuperAdmin
                    ? field.name === "assignedTo" || field.name === "status"
                    : field.name === "screenName"
                ) ? (
                <Autocomplete
                  name={field.name}
                  // options={resourceNames}
                  options={
                    field.name === "screenName"
                      ? resourceNames
                      : field.name === "status"
                      ? statusOption
                      : assignedToOption
                  }
                  getOptionLabel={(option) => {
                    if (
                      field.name === "screenName" ||
                      field.name === "assignedTo" ||
                      field.name === "status"
                    ) {
                      // Assuming you have a way to label the resourceNames options
                      return option; // Or some other way to get the label for resourceNames options
                    } else {
                      return "";
                    }
                  }}
                  value={
                    field.name === "assignedTo"
                      ? assignedToOption.includes(watch(field.name))
                        ? watch(field.name)
                        : null
                      : field.name === "status"
                      ? statusOption.includes(watch(field.name))
                        ? watch(field.name)
                        : null
                      : resourceNames.includes(watch(field.name))
                      ? watch(field.name)
                      : null
                  }
                  onChange={(event, value) => {
                    setValue(field.name, value);
                  }} // Update the form value on change
                  style={{
                    pointerEvents:
                      (viewData && editItemId) ||
                      ((isMonitor || isSuperAdmin) &&
                        field.name !== "assignedTo" &&
                        field.name !== "status")
                        ? "none"
                        : "auto", // Remove hover effect when readOnly
                  }}
                  onBlur={() => {
                    trigger(field.name); // Trigger validation on blur
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field.label}
                      variant="outlined"
                      required={field.required}
                      size="small"
                      fullWidth
                      InputLabelProps={{
                        style: {
                          pointerEvents: "none",
                        },
                      }}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                      error={!!errors[field.name] && !params.inputProps.value}
                      helperText={
                        errors[field.name]?.message &&
                        (!params.inputProps.value
                          ? errors[field.name]?.message
                          : "")
                      }
                      FormHelperTextProps={{ sx: { mb: -3 } }}
                    />
                  )}
                />
              ) : (
                !(field.name === "assignedTo" || field.name === "status") &&
                (field.name !== "conversation" || claOpen) && (
                  <TextField
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    autoFocus={field.autoFocus}
                    multiline={field.multiline}
                    rows={field.rows}
                    autoComplete="off"
                    placeholder={field.placeholder}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onWheel={(event) => {
                      // Check if the input type is number to prevent scrolling
                      if (field.type === "number") {
                        event.target.blur();
                      }
                    }}
                    InputProps={{
                      sx: {
                        "& textarea": {
                          resize: "vertical", // Restrict resizing to vertical only
                        },
                      },
                      readOnly: field.name === "history" ? true : false, // Makes the input read-only
                      inputProps: {
                        max:
                          field.type === "datetime-local"
                            ? "9999-12-31T23:59"
                            : undefined,
                        style: {
                          textTransform:
                            field.type === "datetime-local"
                              ? "uppercase"
                              : "none",
                        },
                      },
                    }}
                    InputLabelProps={{
                      shrink: field.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    FormHelperTextProps={{ sx: { mb: -3 } }}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation on change

                      // Close the time picker pop-up after selecting a time
                      if (field.type === "datetime-local") {
                        setTimeout(() => {
                          e.target.blur(); // Remove focus to close the time picker
                        }, 100); // Delay ensures the selection is registered
                      }
                    }}
                    style={{
                      pointerEvents:
                        (viewData && editItemId) ||
                        ((isMonitor || isSuperAdmin) &&
                          field.name !== "conversation")
                          ? "none"
                          : "auto", // Remove hover effect when readOnly
                    }}
                    onBlur={() => {
                      trigger(field.name); // Trigger validation on blur
                    }}
                  />
                )
              )}
            </Grid>
          ))}

          <Grid container spacing={2} px={2} py={3}>
            <Typography sx={{ pl: 2 }}>
              {isSuperAdmin || isMonitor ? "Attached File" : "Attach a File"} :
            </Typography>
            <Grid item xs={12}>
              {selectedImage && !selectedImage.includes("Image Not Found") ? (
                <div>
                  {selectedImage.map((file, index) => (
                    <div key={index}>
                      <Link
                        onClick={(e) => {
                          if (isSuperAdmin || isMonitor) {
                            handleDownload(file.content, file.name);
                          } else {
                            if (!viewData) {
                              console.log(isSuperAdmin, viewData);
                              handlePopoverOpen(e, index);
                              // Prevent triggering file input click when showing popover
                              e.stopPropagation();
                            }
                          }
                        }}
                      >
                        {file.name}
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </Grid>
            {isViewerOpen && (
              <SimpleImageViewer
                src={selectedImage
                  .filter((file) => file.type === "image")
                  .map((file) => file.content)} // Image sources
                currentIndex={currentIndex} // Pass the clicked image's index
                onClose={closeImageViewer} // Close the viewer
                disableScroll={false} // Optional: Disable scroll outside the viewer
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
              />
            )}
            {!isSuperAdmin && !isMonitor && (
              <>
                <Link
                  variant="subtitle2"
                  onClick={() => fileInputRef.current.click()} // Programmatically trigger the file input click
                  style={{ marginLeft: "16px" }}
                  sx={{
                    marginLeft: "20px",
                    pl: 3,
                    display: "flex",
                    alignItems: "center",
                    cursor: viewData ? "pointer" : "default",
                    opacity: viewData ? 1 : 0,
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <FileUploadOutlined sx={{ fontSize: 15 }} />
                  File upload
                </Link>
                <input
                  type="file"
                  hidden
                  ref={fileInputRef} // Attach ref to the file input
                  onChange={(e) => handleFileChangeLogo(e, replaceIndex)} // Pass the index of the file to be replaced
                  disabled={viewData}
                  multiple
                />
              </>
            )}
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  fileInputRef.current?.click(); // Trigger file input on "Replace Image" click
                  handlePopoverClose(); // Close popover after action
                }}
              >
                <EditTwoTone sx={{ fontSize: 35, color: "blue", pr: 2 }} />
                Replace File
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDeleteFile(replaceIndex); // Call the delete function with the current file index
                  handlePopoverClose(); // Close the popover
                }}
              >
                <DeleteTwoTone sx={{ fontSize: 35, color: "red", pr: 2 }} />{" "}
                Delete File
              </MenuItem>
            </Popover>
          </Grid>
        </Grid>
        {viewData && editItemId ? null : (
          <DialogActions sx={{ p: 0, mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        )}
      </Box>
    </>
  );
}
