import { US_CURRENCY } from "./constants";

// To store the session storage for getItems

export const JWT_TOKEN = () => sessionStorage.getItem("token") || [];

export const ORG_ID = () => sessionStorage.getItem("orgId") || "";

export const ORG_NAME = () => sessionStorage.getItem("organizationName") || "";

export const USER_ID = () => sessionStorage.getItem("beneficiaryId") || "";

export const USER_NAME = () => sessionStorage.getItem("userName") || "";

export const USER_ROLE = () => sessionStorage.getItem("role") || "";

export const COUNTRY_NAME = () => sessionStorage.getItem("countryName") || "";

export const CURRENCY_NAME = () =>
  sessionStorage.getItem("currencyName") || US_CURRENCY;

export const TIME_ZONE = () => sessionStorage.getItem("timeZone") || "";

export const EMAIL_ID = () => sessionStorage.getItem("email") || "";

export const SESSION_ID = () => sessionStorage.getItem("sessionId") || "";

export const IP_ADDRESS = () => sessionStorage.getItem("ipAddress") || "";

export const PROFILE_IMG = () => sessionStorage.getItem("profileImage") || "";

export const SIGN_IN_VALUES = () =>
  JSON.parse(sessionStorage.getItem("signInValue")) || {};

export const IS_MENU = () => JSON.parse(sessionStorage.getItem("menus")) || {};

export const COUNTRY_OBJECT = () =>
  sessionStorage.getItem("countryObject") || "";

export const FILE_UPLOAD_TIME = () =>
  sessionStorage.getItem("isFileUpload") || "";

export const ROLE_ACCESS = () =>
  JSON.parse(sessionStorage.getItem("UserRoleAccess")) || [];

export const IS_MONITOR = () => sessionStorage.getItem("isMonitor") || "false";

export const IS_SUPER_ADMIN = () =>
  sessionStorage.getItem("isSuperAdmin") || "false";

export const START_DATE = () => sessionStorage.getItem("startDate") || "";

export const END_DATE = () => sessionStorage.getItem("endDate") || "";

export const IS_DEMO_USER = () =>
  sessionStorage.getItem("isDemoUser") || "false";

export const IS_BiLLING = () => sessionStorage.getItem("isBilling") || "false";
