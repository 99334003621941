import {
  Box,
  Grid,
  Paper,
  Button,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
  InputLabel,
  Link,
  Divider,
  IconButton,
} from "@mui/material";
import {
  DeleteTwoTone,
  DragIndicatorTwoTone,
  FileDownloadOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import {
  BENEFICIARY_SCREEN,
  CUSTOMER_SCREEN,
  FIELD_MAPPING_SCREEN,
  PRODUCT_SCREEN,
  TRANSACTION_SCREEN,
} from "../../config/constants";
import ProgressCircle from "../../common/ProgressCircle";
import { FieldMappingService } from "./service";
import FullScreenLoader from "../../common/LoadingScreen";

const FieldMapping = () => {
  const {
    PAGE_OPTIONS,
    selectedOption,
    data,
    items,
    fieldContents,
    isEdit,
    setInputFields,
    setSelectedOption,
    handleFileUpload,
    handleDropFileHeading,
    handleDragStart,
    handleDragOver,
    handleDrop,
    EditHandleDrop,
    handleSubmit,
    setFieldContents,
    setUpdatedArray,
    roleAccess,
    stopRemount,
    fileInputRef,
    currentOrders,
    handleDropdownChange,
    isCustMandatory,
    downloadUploadedTemplate,
    handleAddFields,
    handleRemoveCustomField,
    isUploaded,
    custKeys,
    loadLeftSideData,
    setLoadLeftSideData,
    mandatoryKey,
    submitLoading,
  } = FieldMappingService();

  const readAccess = roleAccess.some(
    (item) => item.resource === FIELD_MAPPING_SCREEN && item.writeAccess
  );

  // Function to format label
  function formatLabel(label) {
    try {
      // Insert space before capital letters that are preceded by lowercase letters

      const formattedLabel =
        label.charAt(0).toUpperCase() +
        label
          .slice(1)
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/\s\s+/g, " ")
          .toLowerCase();

      // Split by spaces and capitalize each word
      const capitalizedLabel = formattedLabel
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");

      return capitalizedLabel;
    } catch (error) {
      console.error(error);
    }
  }

  const downloadDefaultTemplate = () => {
    let pdfUrl;

    // Set pdfUrl based on selectedOption
    switch (selectedOption) {
      case BENEFICIARY_SCREEN:
        pdfUrl = "Beneficiary_Template.xlsx";
        break;
      case CUSTOMER_SCREEN:
        pdfUrl = "Customer_Template.xlsx";
        break;
      case PRODUCT_SCREEN:
        pdfUrl = "Product_Template.xlsx";
        break;
      case TRANSACTION_SCREEN:
        pdfUrl = "Transaction_Template.xlsx";
        break;
      default:
        // Handle default case if needed
        break;
    }

    // Create a link element
    const link = document.createElement("a");

    // Set the href and download attributes
    link.href = pdfUrl;
    link.download = pdfUrl; // specify the filename

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click event on the link
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
  };

  const viewData = true; // view purpose only

  let lastY = 0;

  const handleDragEnter = (event) => {
    const currentY = event.clientY;
    if (currentY < lastY) {
      window.scrollBy({ top: -20, behavior: "smooth" });
    } else if (currentY > lastY) {
      window.scrollBy({ top: 20, behavior: "smooth" });
    }
    lastY = currentY;
  };

  return (
    <>
      <Box maxWidth={"xl"}>
        {/* Loader displayed while submitting the form */}
        <FullScreenLoader loading={submitLoading} />

        {/* Header Top Bar */}
        <Grid container spacing={2} sx={{ px: 2, pt: 1.5 }}>
          <Grid item xs={12} md={9}>
            <Typography gutterBottom variant="h6" pl={1.3} color={"secondary"}>
              Add/Edit &nbsp;{selectedOption} Mapping
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Autocomplete
              id="my-autocomplete"
              options={PAGE_OPTIONS}
              size="small"
              fullWidth
              sx={{ py: 1 }}
              value={selectedOption}
              onChange={(event, newValue) => {
                stopRemount.current = true;
                setSelectedOption(newValue);
                setLoadLeftSideData(true);
                setFieldContents({});
                setInputFields([]);
                setUpdatedArray([]);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField {...params} label="Config Master" />
              )}
              renderOption={(props, option, { selected }) => (
                <Typography
                  {...props}
                  key={props.key}
                  color={selected ? "secondary" : "inherit"}
                >
                  {option}
                </Typography>
              )}
            />
          </Grid>
        </Grid>

        <Divider variant="middle" sx={{ pt: 1 }} />

        {/* Body*/}
        <Grid container spacing={2} pt={3}>
          {/* Label Names */}
          <Grid onDragEnter={handleDragEnter} item xs={12} md={7}>
            <Typography
              variant="h6"
              gutterBottom
              pl={3}
              pb={2}
              color={"secondary"}
            >
              CompFact fields vs Mapped fields
            </Typography>
            {!loadLeftSideData ? (
              <>
                {/* Render matching fields */}
                {currentOrders
                  .filter((key) => Object.keys(items).includes(key))
                  .map((label, index) => (
                    <Box key={index}>
                      <Grid
                        container
                        alignItems="center"
                        sx={{ m: 1, px: 2, py: 0.7 }}
                      >
                        <Grid item xs={12} sm={5}>
                          <InputLabel htmlFor={label}>
                            {formatLabel(label)}:
                            {mandatoryKey.includes(label) && (
                              <span style={{ color: "red" }}> * </span>
                            )}
                          </InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box
                            style={{
                              borderRadius: "30px 0px",
                              outline: "2px dashed #6e08f0",
                              outlineOffset: 2,
                              cursor: isEdit
                                ? fieldContents[label]
                                  ? "grabbing"
                                  : "default"
                                : items[label]
                                ? "grabbing"
                                : "default",
                            }}
                          >
                            <Box
                              id={label + "-container"} // Assign an ID to the container box
                              style={{
                                transition:
                                  "transform 0.3s ease-in-out, width 0.3s ease-in-out", // Add transition for smooth scaling and width change
                                transformOrigin: "left", // Set the scaling origin to the center
                                transform: "scale(1)", // Set initial scale
                                width: "100%", // Initial width of the field
                                userSelect: "none", // Prevent text selection
                              }}
                              onMouseEnter={() => {
                                // Change scale and width on hover
                                items[label] &&
                                  (document.getElementById(
                                    label + "-container"
                                  ).style.transform = "scale(0.9)");
                                items[label] &&
                                  (document.getElementById(
                                    label + "-container"
                                  ).style.width = "382px"); // Increase width on hover
                              }}
                              onMouseLeave={() => {
                                // Restore scale and width on mouse leave
                                document.getElementById(
                                  label + "-container"
                                ).style.transform = "scale(1)";
                                document.getElementById(
                                  label + "-container"
                                ).style.width = "100%"; // Restore initial width on mouse leave
                              }}
                            >
                              <TextField
                                id={label}
                                name={label}
                                placeholder="Drop here to map..."
                                value={
                                  isEdit
                                    ? fieldContents[label] ?? ""
                                    : items[label] ?? ""
                                }
                                draggable={
                                  fieldContents[label]
                                    ? true
                                    : false || items[label]
                                    ? true
                                    : false
                                } // Set draggable based on the presence of value in fieldContents or items
                                data-source="allowed-source" // Add data attribute to mark the source
                                onDrop={
                                  isEdit
                                    ? fieldContents[label]
                                      ? (e) => EditHandleDrop(e, label)
                                      : (e) => handleDrop(e, label, index)
                                    : items[label]
                                    ? (e) => EditHandleDrop(e, label)
                                    : (e) => handleDrop(e, label, index)
                                }
                                onDragStart={(e) =>
                                  handleDragStart(e, label, false)
                                }
                                onDragOver={handleDragOver}
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  input: { cursor: items[label] && "grabbing" },
                                }}
                                InputProps={{
                                  style: {
                                    pointerEvents: viewData ? "none" : "auto",
                                    background: isEdit
                                      ? fieldContents[label]
                                        ? "#d7c1fa"
                                        : "transparent"
                                      : items[label]
                                      ? "#d7c1fa"
                                      : "transparent",
                                    cursor: "grabbing",
                                    border: "1px solid #6e08f0",
                                    borderRadius: "30px 0px",
                                    outlineOffset: 2,
                                  },
                                  readOnly: true,
                                  startAdornment: (
                                    isEdit ? fieldContents[label] : items[label]
                                  ) ? (
                                    <InputAdornment position="end">
                                      <DragIndicatorTwoTone />
                                    </InputAdornment>
                                  ) : null,
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}

                {/* Custom Field */}
                {Object.keys(items).filter(
                  (label) => !currentOrders.includes(label)
                ).length > 0 && (
                  <Typography variant="h6" gutterBottom pl={2.9}>
                    Custom Fields
                  </Typography>
                )}

                {/* Render non-matching fields */}
                {Object.keys(items)
                  .filter((label) => !currentOrders.includes(label))
                  .map((label, index) => (
                    <Box key={index}>
                      <Grid
                        container
                        alignItems="center"
                        sx={{ m: 1, px: 2, py: 0.7 }}
                      >
                        <Grid item xs={12} sm={5}>
                          <InputLabel htmlFor={label}>
                            {formatLabel(label)}:
                            {isCustMandatory.includes(label) && (
                              <span style={{ color: "red" }}> * </span>
                            )}
                          </InputLabel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box
                            style={{
                              borderRadius: "30px 0px",
                              outline: "2px dashed #6e08f0",
                              outlineOffset: 2,
                              cursor: items[label] ? "grabbing" : "default",
                            }}
                          >
                            <Box
                              id={label + "-container"} // Assign an ID to the container box
                              style={{
                                transition:
                                  "transform 0.3s ease-in-out, width 0.3s ease-in-out", // Add transition for smooth scaling and width change
                                transformOrigin: "left", // Set the scaling origin to the center
                                transform: "scale(1)", // Set initial scale
                                width: "100%", // Initial width of the field
                              }}
                              onMouseEnter={() => {
                                // Change scale and width on hover
                                items[label] &&
                                  (document.getElementById(
                                    label + "-container"
                                  ).style.transform = "scale(0.9)");
                                items[label] &&
                                  (document.getElementById(
                                    label + "-container"
                                  ).style.width = "382px"); // Increase width on hover
                              }}
                              onMouseLeave={() => {
                                // Restore scale and width on mouse leave
                                document.getElementById(
                                  label + "-container"
                                ).style.transform = "scale(1)";
                                document.getElementById(
                                  label + "-container"
                                ).style.width = "100%"; // Restore initial width on mouse leave
                              }}
                            >
                              <TextField
                                id={label}
                                name={label}
                                placeholder="Drag the values here...."
                                value={items[label] ?? ""}
                                draggable={items[label] ? true : false} // Set draggable based on the presence of value in fieldContents or items
                                data-source="allowed-source" // Add data attribute to mark the source
                                onDrop={
                                  items[label]
                                    ? (e) => EditHandleDrop(e, label)
                                    : (e) => handleDrop(e, label, index)
                                }
                                onDragStart={(e) =>
                                  handleDragStart(e, label, false)
                                }
                                onDragOver={handleDragOver}
                                size="small"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  input: { cursor: items[label] && "grabbing" },
                                }}
                                InputProps={{
                                  style: {
                                    pointerEvents: viewData ? "none" : "auto",
                                    background: items[label]
                                      ? "#d7c1fa"
                                      : "transparent",
                                    cursor: "grabbing",
                                    border: "1px solid #6e08f0",
                                    borderRadius: "30px 0px",
                                    outlineOffset: 2,
                                  },
                                  readOnly: true,
                                  startAdornment: items[label] ? (
                                    <InputAdornment position="end">
                                      <DragIndicatorTwoTone />
                                    </InputAdornment>
                                  ) : null,
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            {!custKeys.includes(label) && (
                              <IconButton
                                onClick={(e) => handleRemoveCustomField(label)}
                                color="error"
                              >
                                <DeleteTwoTone />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </>
            ) : (
              <ProgressCircle />
            )}
          </Grid>

          {/* Draggable Field */}
          <Grid
            item
            xs={12}
            md={5}
            onDrop={(e) => {
              handleDropFileHeading(e);
            }}
            onDragOver={handleDragOver}
          >
            <Box
              sx={{
                borderLeft: "1px solid  rgba(200,200,200,0.5)", // Border style
                width: "100%",
                height: "100%",
                px: 1,
              }}
            >
              <Grid container spacing={2} pl={1}>
                <Grid item xs={12} sm={6}>
                  {readAccess && (
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<FileUploadOutlined />}
                      fullWidth
                    >
                      Upload File
                      <input
                        type="file"
                        accept=".xlsx, .csv"
                        hidden
                        ref={fileInputRef} // Using ref
                        onChange={handleFileUpload}
                      />
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box
                    onDragEnter={handleDragEnter}
                    sx={{
                      display: "flex",
                      flexDirection: "column", // Stack items vertically
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      component="button"
                      variant="subtitle2"
                      underline="always"
                      onClick={downloadDefaultTemplate}
                      sx={{ display: "flex", alignItems: "center" }} // Adjust gap as needed
                    >
                      <FileDownloadOutlined
                        sx={{ fontSize: 15, textDecoration: "underline" }}
                      />
                      Default Template
                    </Link>
                    {isUploaded ? (
                      <Link
                        component="button"
                        variant="subtitle2"
                        underline="always"
                        onClick={downloadUploadedTemplate}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <FileDownloadOutlined
                          sx={{ fontSize: 15, textDecoration: "underline" }}
                        />
                        Uploaded File for Mapping
                      </Link>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom color={"secondary"}>
                    Columns for Mapping / Non-Mapping Fields from uploaded
                    Template
                  </Typography>
                </Grid>
              </Grid>

              {/* Uploading Fields Display */}
              <Box sx={{ m: 1, px: 7, py: 1 }}>
                {/* Uploaded excel file first row all columns displayed as draggable fields */}
                {data.map((index) => (
                  <Box
                    key={index}
                    style={{
                      borderRadius: "30px 0px",
                      outline: "2px dashed #6e08f0",
                      outlineOffset: 2,
                      cursor: "grabbing", // Apply cursor style to the outer container
                    }}
                  >
                    <Box
                      id={`paper-${index}-container`} // Assign an ID to the container box
                      style={{
                        transition:
                          "transform 0.3s ease-in-out, width 0.3s ease-in-out", // Add transition for smooth scaling and width change
                        transformOrigin: "left", // Set the scaling origin to the center
                        transform: "scale(1)", // Set initial scale
                        width: "100%", // Initial width of the field
                      }}
                      onMouseEnter={() => {
                        // Change scale and width on hover
                        const container = document.getElementById(
                          `paper-${index}-container`
                        );
                        if (container) {
                          container.style.transform = "scale(0.9)";
                          container.style.width = "405px"; // Increase width on hover
                        }
                      }}
                      onMouseLeave={() => {
                        // Restore scale and width on mouse leave
                        const container = document.getElementById(
                          `paper-${index}-container`
                        );
                        if (container) {
                          container.style.transform = "scale(1)";
                          container.style.width = "100%"; // Restore initial width on mouse leave
                        }
                      }}
                    >
                      <Paper
                        key={index}
                        id={`paper-${index}`} // Set a unique ID for each Paper component
                        sx={{
                          bgcolor: "#d7c1fa",
                          p: 1,
                          mb: 2,
                          cursor: "grabbing",
                          border: "1px solid #6e08f0",
                          borderRadius: "30px 0px",

                          outlineOffset: 2,
                        }}
                        elevation={3}
                        draggable
                        onDragStart={(e) => {
                          handleDragStart(e, index, true);
                        }}
                        onDragOver={handleDragOver}
                      >
                        <DragIndicatorTwoTone
                          style={{ transform: "rotate(90deg)" }}
                        />
                        <span className="pl-2">{index}</span>
                      </Paper>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Footer */}
        <Grid container justifyContent="flex-end" sx={{ p: 2 }} spacing={2}>
          <Grid item>
            <Button
              onClick={(e) => handleAddFields(e)}
              variant="outlined"
              color="primary"
            >
              Add Field
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              component="label"
              type="submit"
              onClick={handleSubmit}
            >
              {!isEdit ? "Update" : "Submit"}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleDropdownChange}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FieldMapping;
