import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { PrivacyTipTwoTone } from "@mui/icons-material";
import { IS_BiLLING, IS_DEMO_USER } from "../config/sessionStorage";

export default function DemoUserInfo({ setIsAuthenticated, setRes }) {
  const navigate = useNavigate();

  const isBilling = IS_BiLLING();

  useEffect(() => {
    const isDemoUser = IS_DEMO_USER();

    // Check if isDemoUser is null or undefined
    if (isDemoUser === "false") {
      setIsAuthenticated(false); // No session data, log out
      setRes(false);
      sessionStorage.clear(); // Clear session storage for logout
      navigate("/sign_in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "0 20px",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              alignItems: "center",
            }}
          >
            <PrivacyTipTwoTone
              color="info"
              sx={{ fontSize: { xs: 50, sm: 80, md: 100 } }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" } }}
              gutterBottom
            >
              Welcome, Demo User!
            </Typography>

            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "1rem", sm: "1.125rem" }, mb: 2 }}
            >
              MFA is not required for demo users. Click below to go to your
              dashboard.
            </Typography>

            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={isBilling === "true" ? "/billing" : "/dashboard"}
              onClick={() => {
                sessionStorage.removeItem("isDemoUser");
                sessionStorage.removeItem("isBilling");
              }}
            >
              Go to Dashboard
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
